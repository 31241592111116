import React, { Fragment, useState, useEffect } from 'react'
import {t} from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Button, Card, CardBody, CardFooter } from 'reactstrap'
import { useRecoilValue } from 'recoil'
import axios from 'axios'
import Swal from 'sweetalert2'

import env from '../../../env/src_config';
import { headersState } from '../../../recoil/recoil';
import { isNull } from '../../../izUtils'
import LinkedSystemList from './lists/LinkedSystemList'
import { transformer } from '../../../helpers/fields'
import { axiosError, errorStatus } from '../../../helpers/response';
import Spinner from '../../spinner/Spinner';
import PaginationTop from '../../Pagination/PaginationTop';
import PaginationBottom from '../../Pagination/PaginationBottom';
import AddUpdateLinkedSystem from './AddUpdateLinkedSystem'
import ContractComponent from '../contracts/ContractComponent';

const LinkedSystemComponent = () => {
    const Navigate = useNavigate();
    const { clientId, facilityId, linkedSystemId } = useParams();

    const headers = useRecoilValue(headersState);
    const [linkedSystems, setLinkedSystems] = useState(null)
    const [filters, setFilters] = useState(null)

    useEffect(() => {
        if (facilityId === 'create') {
            setLinkedSystems([]);
            setFilters([]);
        } else {
            getLinkedSystems({});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getLinkedSystems = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }

        axios.post(env.api + '/api/client/facility/system/list/' + facilityId, payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                if (!isNull(response.data.filters)) setFilters(transformer(response.data.filters));
                setLinkedSystems(response.data.data);
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const handleDelete = (row) => {
        Swal.fire({
            text: t('facilities.linkedSystems.list.delete'),
            icon: 'question',
            confirmButtonColor: 'var(--theme-default)',
            confirmButtonText: t('ok'),
            showCancelButton: true,
            cancelButtonText: t('cancel'),
        }).then(val => {
            if (val.isConfirmed) {
                axios.delete(env.api + '/api/client/facility/system/' + row.id, {headers}).then(response => {
                    if (response.data.state === 'success') {
                        Navigate('/clients/' + clientId + '/facilities/' + facilityId);
                        getLinkedSystems({});
                        Swal.fire({
                            text: response.data.message,
                            icon: 'success',
                            confirmButtonColor: 'var(--theme-default)',
                            confirmButtonText: t('ok'),
                        })
                    } else {
                        errorStatus(response.data, t);
                    }
                }).catch(error => {
                    axiosError(error, Navigate);
                });
            }
        })
    }

    if (isNull(linkedSystems)) {
        return <Spinner />
    } else {
        return (
            <Fragment>
                <hr />
                <Col lg="6">
                    <Card className="ribbon-wrapper">
                        <CardBody>
                        <div className="ribbon ribbon-clip ribbon-primary">{t('facilities.linkedSystems.list.title')}</div>
                            <div>
                                <PaginationTop filters={filters} getData={clonedFilters => getLinkedSystems(clonedFilters, false)} />
                                <LinkedSystemList data={linkedSystems} handleDelete={handleDelete} />
                                <PaginationBottom filters={filters} getData={clonedFilters => getLinkedSystems(clonedFilters, false)} />
                            </div>
                        </CardBody>
                        <CardFooter>
                            <div className='text-end'>
                                <Button color="primary" className="mt-2 me-2" onClick={() => Navigate('/clients/' + clientId + '/facilities/' + facilityId + '/linked-systems/create')}>{t('facilities.linkedSystems.list.addNew')}</Button>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
                {!isNull(linkedSystemId) &&
                    <>
                        <Col lg="6">
                            <AddUpdateLinkedSystem getLinkedSystems={() => getLinkedSystems({})} />
                        </Col>
                        {(linkedSystemId !== 'create') &&
                            <div id="linkedSystemContracts">
                                <hr />
                                <Row>
                                    <Col lg="6">
                                        <ContractComponent />
                                    </Col>
                                </Row>
                            </div>
                        }
                    </>
                }
            </Fragment>
        )
    }
}

export default LinkedSystemComponent