import React, {Fragment, useState, useEffect} from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import {t} from 'i18next'
import moment from 'moment/moment';
import DatePicker from "react-datepicker";

import { isNull, dateFormatForBE, fieldsWithCheckboxes } from '../../izUtils';
import DisplayHistory from '../../helpers/DisplayHistory';
import { checkHistory } from '../../helpers/checkHistory';

const DateInput = ({data, onChange, showErrors, showTime, futureExceptions, lsFilterName, history}) => {
    const { name, display, errors, title, disabled, mandatory} = data;
    const isFilter = !isNull(lsFilterName)

    const [date, setDate] = useState("");
    const [checkboxValue, setCheckboxValue] = useState(false)

    useEffect(() => {
        setDate(isNull(data.value) || data.value === '' ? '' : moment(moment.utc(data.value).local().format()).toDate());
        setCheckboxValue(isNull(data.value) || data.value === '' ? true : false)

    }, [data.value]);

    const handleDate = (val) => {
        let withTime = showTime;

        // Set time for exception_from and exception_to
        if (data.name === 'exception_from' ) {
            val = new Date(moment(val).set('hour', 0).set('minute', 0))
            withTime = true
        } else if (data.name === 'exception_to') {
            val = new Date(moment(val).set('hour', 23).set('minute', 59))
            withTime = true
        } else if (name === 'service_from' && (isNull(date) || date === '')) { // Set time to 07:00 for service_from, if it's empty
            val = new Date(moment(val).set('hour', 7).set('minute', 0))
        }

        setDate(val)
        const formatValue = withTime ? moment.utc(val).format() : moment.utc(val).local().format()

        // Save filter data to LS, so it can be used if user clicks "Confirm" button
        if (isFilter) {
            let lsFilter = JSON.parse(localStorage.getItem(lsFilterName))
            if (!isNull(lsFilter)) {
                for (const filterName in lsFilter) {
                    if (filterName === name) {
                        lsFilter[filterName].value = dateFormatForBE(val === null ? '' : formatValue, withTime);
                        localStorage.setItem(lsFilterName, JSON.stringify(lsFilter))
                    }
                }
            }
        } else {
            onChange(dateFormatForBE(val === null ? '' : formatValue, withTime), name);
        }
    };

    // Disable dates for exceptions
    let excludeDateIntervals = [];
    if (!isNull(futureExceptions)) {
        futureExceptions.forEach(exception => {
            excludeDateIntervals.push({
                start: moment(exception.from).toDate(),
                end: moment(exception.to).toDate(),
            })
        })
    }

    // Handle checkbox after text input
    const handleCheckbox = () => {
        if (!checkboxValue) {
            setDate('')
            onChange(dateFormatForBE('', showTime), name);
        }
        setCheckboxValue(!checkboxValue)
    }

    let hasCheckbox = false
    let displayDateInput = true
    if (!isFilter && fieldsWithCheckboxes.indexOf(name) !== -1) {
        hasCheckbox = true
        if (checkboxValue) displayDateInput = false
    }
    // END handle checkbox after text input

    // Show warning if past value is selected
    let valueInPast = false;
    if ((['service_from'].indexOf(data.name) !== -1) && (!isFilter)) {
        if ((!isNull(date)) && (date.length !== 0) && (date - new Date() < 0)) {
            valueInPast = true;
        }
    }

    if (display) {
        let hasHistory = false;
        if (!isNull(history)) {
            hasHistory = checkHistory(data, history)
        }

        return (
            <FormGroup className={hasHistory ? 'red-alert' : ''}>
                <Label>{title + (mandatory ? " *" : "")}</Label>
                {displayDateInput &&
                    <Fragment>
                        <DatePicker
                            className={"datepicker-here form-control"}
                            selected={date}
                            onChange={handleDate}
                            dateFormat={showTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'}
                            calendarStartDay={1}
                            disabled={disabled}
                            excludeDateIntervals={excludeDateIntervals}

                            showTimeSelect={showTime}
                            timeFormat="HH:mm"
                            timeIntervals={5}
                        />
                        {valueInPast &&
                            <div className="mb-1"><b>{title + ' ' + t('form.note.valueInPast')}</b></div>
                        }
                    </Fragment>
                }
                {hasCheckbox &&
                    <div className="form-checkbox-group ms-4 mt-1">
                        <Input
                            className={"custom-control-input"}
                            id={'checkbox-'+name}
                            type="checkbox"
                            onChange={handleCheckbox}
                            checked={checkboxValue}
                            disabled={disabled}
                        />
                        <Label className="checkmark" htmlFor={'checkbox-'+name}></Label>
                        <Label className="custom-control-label" htmlFor={'checkbox-'+name} style={{ cursor: 'pointer', fontWeight: '400' }} >{t('form.note.indefinitely')}</Label>
                        <div className="invalid-feedback">{"Example invalid feedback text"}</div>
                    </div>
                }
                {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && <div style={{ color: "red", marginBottom: "10px" }}>{errors.map((error, index) => <div key={"error-"+index}>{error}</div>)}</div>}
                {hasHistory && <DisplayHistory history={history} />}
            </FormGroup>
        )
    } else {
        return null;
    }
}

export default DateInput