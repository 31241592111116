import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup, Row, Col, Button } from 'reactstrap'

import { customStyles, isNull } from '../../izUtils'
import { generateQR } from '../../helpers/generateQR'

const AllFacilityList = ({data, headers, token, showSystems, showContact, showInputs}) => {
    const Navigate = useNavigate()

    const handleRowClick = (state) => {
        if (token.parsedToken.type !== 'technician') {
            Navigate('/clients/' + state?.client?.id + '/facilities/' + state.id)
        }
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        let tColumns = [
            {
                name: t('table.facilityName'),
                selector: row => row.name,
                sortable: true,
            }
        ];

        if (token.parsedToken.type !== 'technician') {
            tColumns.push(
                {
                    name: t('table.qrCode'),
                    sortable: false,
                    cell: row => {
                        return <Button color="primary" onClick={() => generateQR(row.id, headers)}>QR</Button>
                    }
                }
            )
        }

        tColumns.push(
            {
                name: t('table.client'),
                selector: row => row.client?.client,
                sortable: true,
            },
            {
                name: t('table.google_location'),
                selector: row => row.google_location,
                sortable: true,
                cell: row => {
                    if (!isNull(row.google_location) && row.google_location.length !== 0) {
                        return <div className="">
                            {row.google_location}
                            <a href={'https://www.google.com/maps/place/' + row.google_location} className='ms-1' target="_blank" rel="noreferrer">
                                <i className="fa fa-map-marker"></i>
                            </a>
                        </div>
                    } else return "";
                }
            },
            {
                name: t('table.security_systems'),
                sortable: false,
                grow: 4,
                cell: row => {
                    if (!isNull(row.security_systems) && row.security_systems.length !== 0) {
                        return (
                            <Col sm="12">
                                <Row className='d-none d-lg-flex m-0 py-1'>
                                    <Col lg="4" className="fw-bold">{t('table.security_system')}</Col>
                                    <Col lg="4" className="fw-bold">{}</Col>
                                    <Col lg="4" className="fw-bold">{t('table.contract')}</Col>
                                </Row>
                                <div className='contract-system-list'>
                                    {row.security_systems.map((system, i) => (
                                        <Row key={"system-"+i} className="align-items-center m-0 py-1">
                                            <Col lg="4"><span className="d-inline d-lg-none fw-bold">{t('table.security_system')}: </span>{!isNull(system.name) && system.name.title}</Col>
                                            <Col lg="4"><Button color="primary" className="btn-outline-primary btn-sm py-1 px-2 my-1" onClick={() => showSystems(system)}>{t('showSystems')}</Button></Col>
                                            <Col lg="4">
                                                <span className="d-inline d-lg-none fw-bold">{t('table.contract')}: </span>
                                                {system.valid ?
                                                    <span className='badge badge-success'>{t('yes')}</span>
                                                    :
                                                    <span className='badge badge-danger'>{t('no')}</span>
                                                }
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            </Col>
                        )
                    } else {
                        return ""
                    }
                }
            }
        )

        if (token.parsedToken.type === 'technician') {
            tColumns.push(
                {
                    name: t('table.contact'),
                    sortable: false,
                    cell: row => {
                        return <Button color="primary" className="btn-sm py-1 px-2" onClick={() => showContact(row)}>{t('facilities.contacts.show')}</Button>
                    }
                },
                {
                    name: "",
                    sortable: false,
                    cell: row => {
                        return <Button color="primary" className="btn-sm py-1 px-2" onClick={() => showInputs(row)}>{t('facilities.inputs.change')}</Button>
                    }
                }
            )
        }

        return tColumns;
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default AllFacilityList