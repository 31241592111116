import React, { Fragment, useMemo, useCallback, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { useNavigate, useParams } from 'react-router-dom';
import {t} from 'i18next'
import { useRecoilValue } from 'recoil';
import moment from 'moment'
import {Dropdown, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import 'moment/locale/sl'

import { getNavigateNumbers, rangeFunc, displayDate, isNull, isInRange, getTimezoneOffset } from '../../izUtils';
import { AgendaView } from './view/AgendaView';
import { ReversedAgendaView } from './view/ReversedAgendaView';
import Loader from '../spinner/Loader';
import { handleEvent } from './helper/handleEvent';
import { tokenState } from '../../recoil/recoil';
import CalendarScroll from './CalendarScroll';
import { sortEvent } from './helper/sortEvent';

const localizer = momentLocalizer(moment)

const Calender = ({calendarData, view, loading, lsFilterName}) => {
    const Navigate = useNavigate()
    const {year/*, weekNumber*/} = useParams();
    const token = useRecoilValue(tokenState);
    const calendarRef = useRef(null)

    let events = useMemo(() => {
        return sortEvent(calendarData);

    }, [calendarData.data, calendarData.events]) // eslint-disable-line react-hooks/exhaustive-deps

    const onNavigate = (date, view/*, action*/) => {
        const navigateNumbers = getNavigateNumbers(date);

        if (view === 'agenda') {
            Navigate('/schedule/users/' + navigateNumbers.year + '/' + navigateNumbers.week)
        } else {
            Navigate('/schedule/' + navigateNumbers.year + '/' + navigateNumbers.week)
        }
    }

    const handleSelectEvent = useCallback(event => handleEvent(event, t, Navigate, token), []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectWeek = (week) => {
        calendarRef.current.handleNavigate('CUSTOM-WEEK', week.start)
    }

    const handleSelectYear = (year) => {
        calendarRef.current.handleNavigate('CUSTOM-YEAR', moment().isoWeekYear(year).isoWeek(1).startOf('week').toDate())
    }

    const handleToday = () => {
        calendarRef.current.handleNavigate('CUSTOM-TODAY', moment.utc().startOf('week').toDate())
    }

    const messages = {
        next: t('calendar.next'),
        previous: t('calendar.previous'),
        today: t('calendar.today'),
        month: t('calendar.month'),
        week: t('calendar.week'),
        day: t('calendar.day'),
        agenda: t('calendar.agenda'),
    }

    const eventStyleGetter = (event) => {
        let newStyle = {};
        if (event.type === 'task'){
            newStyle.backgroundColor = "lightgreen"
            newStyle.color = "#000"
        }

        return {style: newStyle};
    }

    let views = {'week': true}
    if (view === 'agenda') views = {'agenda': AgendaView}
    let lsFilterData = JSON.parse(localStorage.getItem(lsFilterName));
    if (lsFilterData?.technician?.value && lsFilterData?.technician?.value.length !== 0) {
        views = {'agenda': ReversedAgendaView}
    }

    // Weeks dropdown
    const rangeWeeks = useMemo(() => {
        let firstDay = null;
        let lastDay = null;
        if (isNull(year)) {
            firstDay = moment.utc().startOf('year').toDate();
            lastDay = moment.utc().endOf('year').toDate();
        } else {
            firstDay = moment.utc().year(year).startOf('year').toDate()
            lastDay = moment.utc().year(year).endOf('year').toDate();
        }

        return rangeFunc(moment.utc(firstDay).startOf('week').toDate(), moment.utc(lastDay).endOf('week').toDate(), 'week');
    }, [year])

    let weeks = useMemo(() => {
        if (!isNull(rangeWeeks)) {
            return rangeWeeks.map(startOfTheWeek => {
                return {
                    number: moment(startOfTheWeek).isoWeek(),
                    start: startOfTheWeek,
                    end: moment(startOfTheWeek).endOf('week').toDate()
                }
             });
        } else {
            return []
        }
    }, [rangeWeeks])
    // End weeks dropdown

    // Years dropdown
    let yearsDropdown = useMemo(() => {
        let yearsDropdownArray = [];
        for (let i = parseInt(calendarData.first_task); i <= parseInt(calendarData.last_task); i++) {
            yearsDropdownArray.push(i+'')
        }

        return yearsDropdownArray;
    }, [calendarData.first_task, calendarData.last_task])
    // End years dropdown

    return (
        <Fragment>
            {loading && <Loader />}
            <div className='d-flex flex-wrap mb-3'>
                <div>
                    <Button color="primary" className="mb-1 me-3" style={{ padding: '12px 35px', maxHeight: '43px' }} onClick={handleToday}>{t('calendar.today')}</Button>
                </div>
                <div className="dropdown-basic mb-1 me-3">
                    <Dropdown className="dropdown" isOpen={true} toggle={() => {}}>
                        <Button color="primary" className="dropbtn">{t('calendar.byYear')} <span><i className="icofont icofont-arrow-down"></i></span></Button>
                        <DropdownMenu className="dropdown-content">
                            {yearsDropdown.map((yearDropdown, index) => {
                                let selectedYear = false;
                                if (isNull(year)) {
                                    if (yearDropdown === moment().year()+'') selectedYear = true;
                                } else {
                                    if (yearDropdown === year) selectedYear = true;
                                }
                                return (
                                    <DropdownItem
                                        key={index+"-year-"+yearDropdown}
                                        style={selectedYear  ? { backgroundColor:  'var(--theme-default)', color: '#fff' } : {} }
                                        onClick={() => handleSelectYear(yearDropdown)}
                                    >
                                        {yearDropdown}
                                    </DropdownItem>
                                )}
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div className="dropdown-basic mb-1 me-3">
                    <Dropdown className="dropdown" isOpen={true} toggle={() => {}}>
                        <Button color="primary" className="dropbtn">{t('calendar.byWeek')} <span><i className="icofont icofont-arrow-down"></i></span></Button>
                        <DropdownMenu className="dropdown-content custom-date-dropdown-content">
                            {weeks.map((week, index) => {
                                const selectedWeek = isInRange(moment(calendarData.from).add(getTimezoneOffset(), 'hour').toDate(), week.start, week.end, 'day')
                                return (
                                    <DropdownItem
                                        key={index+"-week-"+week.number}
                                        style={selectedWeek  ? { backgroundColor:  'var(--theme-default)', color: '#fff' } : {} }
                                        onClick={() => handleSelectWeek(week)}
                                    >
                                        <b>{t('calendar.week') + ' ' + week.number}:</b>&nbsp;{displayDate(week.start, 'date')}-{displayDate(week.end, 'date')}
                                    </DropdownItem>
                                )}
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>

            <Calendar
                ref={calendarRef}
                localizer={localizer}
                defaultDate={moment(calendarData.from).add(getTimezoneOffset(), 'hour').toDate()}
                onSelectEvent={handleSelectEvent}
                onNavigate={onNavigate}
                events={events}
                defaultView={view}
                views={views}
                step={30}
                startAccessor="start"
                endAccessor="end"
                messages={messages}
                eventPropGetter={eventStyleGetter}
            />

            <CalendarScroll
                calendarData={calendarData}
                localizer={localizer}
                view={view}
                views={views}
                messages={messages}
                eventStyleGetter={eventStyleGetter}
                lsFilterName={lsFilterName}
            />

        </Fragment>
    );

};

export default Calender;