import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'
import { FormGroup } from 'reactstrap'
import DataTable from 'react-data-table-component'
import moment from 'moment'

import { customStyles, displayDate, isNull } from '../../../izUtils'
import { tokenState } from '../../../recoil/recoil'

const ArchiveList = ({data}) => {
    const Navigate = useNavigate()
    const token = useRecoilValue(tokenState);

    const handleRowClick = (state) => {
        if (token.parsedToken.type === 'accountant') {
            Navigate('/task/' + state.id + '/update')
        } else {
            Navigate('/task/' + state.id)
        }
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        return [
            {
                name: t('table.client'),
                selector: row => row.client.client,
                sortable: true,
                cell: row => (
                    <div className='d-flex flex-column' onClick={() => handleRowClick(row)}>
                        <div>{row.client?.client}</div>
                        <div className='small'>{row.client?.location}</div>
                    </div>
                )
            },
            {
                name: t('table.service_type'),
                selector: row => !isNull(row.service_type) ? row.service_type.title : '',
                sortable: true,
            },
            {
                name: t('table.admin'),
                selector: row => (!isNull(row.processed_by_admin) && !isNull(row.processed_by_admin.name)) ? row.processed_by_admin.name : '',
                sortable: true,
            },
            {
                name: t('table.technician'),
                selector: row => row.location,
                sortable: true,
                cell: row => <div className='d-flex flex-column' onClick={() => handleRowClick(row)}>{row.technicians?.map((tech,idx) => <span key={idx}>{tech.name}</span>)}</div>
            },
            {
                name: t('table.archived_at'),
                selector: row => !isNull(row.archived_at) ? displayDate(moment.utc(row.archived_at)) : '',
                sortable: true,
            },
            {
                name: t('table.invoice_number'),
                selector: row => !isNull(row.invoice_number) ? row.invoice_number : '',
                sortable: true,
            },
        ]
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default ArchiveList