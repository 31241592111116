import axios from "axios";
import { t } from "i18next";
import Swal from "sweetalert2";

import env from "../env/src_config";
import { axiosError } from "./response";
import { isNull } from "../izUtils";

export const changeStatus = async (save, status, id, headers, Navigate, form = null, changeSupervisor = false) => {
    const payload = { save };
    if (!isNull(status)) payload["status"] = status;
    if (!isNull(form)) payload.data = form;
    if (changeSupervisor) payload.change_supervisor = true;

    let statusButtons = await axios.patch(env.api + '/api/task/status/' + id, payload, {headers}).then(async response => {
        if (save) {
            // Show swall form for no contract or for contract without supervisors
            if (!isNull(response.data.data) && response.data.data.length !== 0) {
                let title = !isNull(response.data.data.message) ? response.data.data.message : t('task.updateStatus.noContract.title');
                if (status === 'in_approval' && changeSupervisor) {
                    title = t('task.updateStatus.changeSupervisor.otherTitle');
                }

                const { value: formValues } = await Swal.fire({
                    title: title,
                    html: `
                        <div class="p-2">
                            <div class="mb-3">
                                <label class="form-label">${t('name')}</label>
                                <input id="swal_name" class="form-control" name="name">
                            </div>
                            <div class="mb-3">
                                <label class="form-label">${t('email')}</label>
                                <input id="swal_email" class="form-control" name="email">
                            </div>
                        </div>
                    `,
                    showCancelButton: true,
                    cancelButtonText: t('cancel'),
                    focusConfirm: false,
                    preConfirm: () => {
                        let inputs = {
                            name: document.getElementById("swal_name").value,
                            email: document.getElementById("swal_email").value,
                        }

                        if (isNull(inputs.name) || inputs.name.length === 0) {
                            document.getElementById(`swal_name`).classList.add('mandatory');
                            Swal.showValidationMessage(t('task.updateStatus.noContract.error.name'));
                        } else if (isNull(inputs.email) || inputs.email.length === 0) {
                            document.getElementById(`swal_email`).classList.add('mandatory');
                            Swal.showValidationMessage(t('task.updateStatus.noContract.error.email'));
                        } else {
                            return inputs;
                        }
                    }
                });

                if (formValues) {
                    changeStatus(true, status, id ,headers, Navigate, formValues, changeSupervisor)
                }
            } else {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        // if (status === 'preview') {
                        //     Navigate('/task/' + id + '/update')
                        // } else {
                            Navigate('/')
                        // }
                    })
                } else {
                    Swal.fire({
                        title:t('error'),
                        text: response.data.message,
                        icon: 'error',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    })
                }
            }
        } else {
            return response.data.available_status
        }
    }).catch(error => {
        axiosError(error);
    });

    return statusButtons;
}