import React from "react";
import { Button, Input } from 'reactstrap'
import { t } from 'i18next'

import { isNull } from "../../../izUtils";

export const FilterComponentText = ({ filterValue, onFilter, onClear }) => (
    <div className="d-flex mb-1">
        <Input
            id="search"
            type="text"
            placeholder={t('technicianName')}
            value={filterValue}
            onChange={onFilter}
        />
        <Button color="primary" className="ps-3 pe-3 ms-2" onClick={() => onClear('text')}><i className="fa fa-times"></i></Button>
    </div>
);

export const FilterComponentSelect = ({ filterValue, onFilter, onClear }) => {

    let options = [
        { id: 'it_video', title: 'IT - video' },
        { id: 'servicer', title: 'Serviser' },
        { id: 'installer', title: 'Montaža in zagon' },
        { id: 'electrician', title: 'Električar' },
        { id: 'external_contractor', title: 'Zunanji sodelavec' },
    ]

    return (
        <div className="d-flex mb-1">
            <Input
                id="search"
                type="select"
                value={filterValue}
                onChange={onFilter}
            >
                <option key="default-option" value={""} style={{ fontWeight: '600' }}>{t('technicianDepartment')}</option>
                {!isNull(options) &&
                    options.map((option, index) => (
                        <option key={index} value={option.id}>{option.title}</option>
                    ))
                }
            </Input>
            <Button color="primary" className="ps-3 pe-3 ms-2" onClick={() => onClear('select')}>
                <i className="fa fa-times"></i>
            </Button>
        </div>
    );
};