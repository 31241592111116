import React, { useState, useEffect } from 'react'
import {t} from 'i18next'
import { FormGroup, Label, Row, Col, Button, Input } from 'reactstrap'

import { isNull } from '../../../izUtils'

const ProjectTechnicians = ({ data, onChange, showErrors }) => {

    const [technicians, setTechnicians] = useState(null)

    useEffect(() => {
        let values = null
        if (isNull(data.value) || data.value === '' || data.value.length === 0) {
            values = [];
        } else {
            values = data.value;
        }

        setTechnicians(values);

    }, [/*data.value*/]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleTechnician = (e, type, i) => {
        let clonedTechnicians = [...technicians];
        clonedTechnicians[i] = {
            ...clonedTechnicians[i],
            [type]: e.target.value
        };
        setTechnicians(clonedTechnicians);

        onChange(clonedTechnicians, data.name)
    }

    const addTechnician = () => {
        let clonedTechnicians = [...technicians];
        clonedTechnicians.push({ id: "", worklog_from: "", worklog_to: "" });
        setTechnicians(clonedTechnicians)
    }

    const deleteTechnician = (index) => {
        let clonedTechnicians = [...technicians];
        clonedTechnicians.splice(index, 1);
        setTechnicians(clonedTechnicians)
    }

    if (data.display) {
        if (technicians === null) return null
        return (
            <FormGroup style={{ position: 'relative' }}>
                <Label>{data.title}</Label>
                <div>
                    {technicians.map((tech, index) => (
                        <div key={index}>
                            <Row className={"align-items-end"}>
                                <Col sm="12" md="7" className="mb-2">
                                    {index === 0 && <Label>{t(`form.label.technicians.name`)}</Label>}
                                    <Input
                                        className={"form-control "}
                                        type="select"
                                        name={"id"}
                                        onChange={(e) => handleTechnician(e, 'id', index)}
                                        value={isNull(tech.id) ? "" : tech.id}
                                        disabled={data.disabled}
                                    >
                                        <option key="default-option" value={""} style={{ fontWeight: '600' }}>{t('defaultSelect')}</option>
                                        {!isNull(data.values) &&
                                            data.values.map((option, index) => (
                                                <option key={index} value={option.id}>{option.title}</option>
                                            ))
                                        }
                                    </Input>
                                </Col>
                                <Col sm="12" md="2" className="mb-2">
                                    {index === 0 && <Label>{t(`form.label.technicians.worklog_from`)}</Label>}
                                    <div className={'d-flex align-items-center'}>
                                        <input
                                            type="time"
                                            name={"worklog_from"}
                                            className="form-control digits form-control"
                                            disabled={data.disabled}
                                            value={isNull(tech.worklog_from) ? "" : tech.worklog_from}
                                            onChange={(e) => handleTechnician(e, 'worklog_from', index)}
                                        />
                                    </div>
                                </Col>
                                <Col sm="12" md="2" className="mb-2">
                                    {index === 0 && <Label>{t(`form.label.technicians.worklog_to`)}</Label>}
                                    <div className={'d-flex align-items-center'}>
                                        <input
                                            type="time"
                                            name={"worklog_to"}
                                            className="form-control digits form-control"
                                            disabled={data.disabled}
                                            value={isNull(tech.worklog_to) ? "" : tech.worklog_to}
                                            onChange={(e) => handleTechnician(e, 'worklog_to', index)}
                                        />
                                    </div>
                                </Col>
                                <Col sm="12" md="1" className="mb-2 text-end">
                                    <Button color="secondary" disabled={data.disabled} className={"px-3"} onClick={() => deleteTechnician(index)}><i className="fa fa-trash"></i></Button>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>
                {!isNull(showErrors) && showErrors && !isNull(data.errors) && data.errors.length !== 0 && <div style={{ color: "red", marginBottom: "10px" }}>{data.errors.map((error, index) => <div key={"error-"+index}>{error}</div>)}</div>}
                <div>
                    <Button color="primary" className='me-2 mb-2' onClick={addTechnician}>{t('projects.projectMaterials.add.technician')}</Button>
                </div>
            </FormGroup>
        )
    } else {
        return null;
    }
}

export default ProjectTechnicians