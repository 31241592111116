import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap';
import { Row, Col, Card, CardBody } from 'reactstrap'

import ServiceItem from './ServiceItem'
import { isNull } from '../../izUtils';

const ListService = ({services, permissions, type, getPriceData}) => {
    const [isOpen, setIsOpen] = useState([...Array(services.length).keys()]);

    const toggle = (id) => {
        if (isOpen.indexOf(id) === -1) {
            setIsOpen(val => [...val, id]);
        } else {
            setIsOpen(val => val.filter(item => item !== id));
        }
    }

    if (services.length !== 0) {
        return (
            <Row>
                <Col sm="12">
                    <Accordion>
                        <Card>
                            <CardBody className='single-service-item'>
                                <div className="default-according style-1" id="accordionclose">
                                    {services.map((service, index) => (
                                        <ServiceItem
                                            key={isNull(service.id) ? 'service-item-'+index : service.id}
                                            service={service}
                                            index={index}
                                            toggle={toggle}
                                            isOpen={isOpen}
                                            permissions={permissions}
                                            type={type}
                                            getPriceData={getPriceData}
                                        />
                                    ))}
                                </div>
                            </CardBody>
                        </Card>
                    </Accordion>
                </Col>
            </Row>
        )
    }
}

export default ListService