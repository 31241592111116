import React, {useState, useEffect, useMemo} from 'react'
import { t } from 'i18next'
import { FormGroup, Label, Input, Button, Modal } from 'reactstrap'

import { isNull } from '../../izUtils'
import DisplayHistory from '../../helpers/DisplayHistory'
import { checkHistory } from '../../helpers/checkHistory'
import AddFacilityCategory from '../facilities/modals/AddFacilityCategory'

const Select = ({data, onChange, showErrors, lsFilterName, history}) => {
    const { name, display, errors, title, values, disabled, mandatory} = data
    const isFilter = !isNull(lsFilterName)

    const [value, setvalue] = useState("")
    const [modalAddFacilityCategory, setModalAddFacilityCategory] = useState(false);

    useEffect(() => {
        setvalue(data.value === null ? '' : data.value)
    }, [data.value])

    const handleChange = (e) => {
        setvalue(e.target.value)

        // Save filter data to LS, so it can be used if user clicks "Confirm" button
        if (isFilter) {
            let lsFilter = JSON.parse(localStorage.getItem(lsFilterName))
            if (!isNull(lsFilter)) {
                for (const filterName in lsFilter) {
                    if (filterName === name) {
                        lsFilter[filterName].value = e.target.value;
                        localStorage.setItem(lsFilterName, JSON.stringify(lsFilter))

                        const callBEAfterChange = ['client', 'facility', 'security_system', 'manufacturer', 'system', 'central'] // BE needs to be called after changing one of these filters, because they are related to other filters
                        if (callBEAfterChange.indexOf(name) !== -1) {
                            onChange(e.target.value, name)
                        }
                    }
                }
            }
        } else {
            onChange(e.target.value, name)
        }
    };

    // Set text in default option
    const defaultSelect = useMemo(() => {
        if (['manufacturer', 'system', 'central_station'].indexOf(name) !== -1 ) {
            return t('unknown')
        } else {
            return t('defaultSelect')
        }
    }, [name])

    let hasHistory = false;
    if (!isNull(history)) {
        hasHistory = checkHistory(data, history)
    }

    let addCategory = false;
    if (name === 'facility_category') {
        addCategory = true;
    }

    if (display) {
        return (
            <FormGroup className={hasHistory ? 'red-alert' : ''}>
                <Label>{title + (mandatory ? " *" : "")}</Label>
                <Input
                    className={"form-control digits"}
                    type="select"
                    name={name}
                    value={value}
                    disabled={disabled}
                    onChange={handleChange}
                >
                    <option key="default-option" value={""} style={{ fontWeight: '600' }}>{defaultSelect}</option>
                    {!isNull(values) &&
                        values.map((option, index) => (
                            <option key={index} value={option.id}>{option.title}</option>
                        ))
                    }
                </Input>
                {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && <div style={{ color: "red", marginBottom: "10px" }}>{errors.map((error, index) => <div key={"error-"+index}>{error}</div>)}</div>}
                {hasHistory && <DisplayHistory history={history} />}
                {addCategory &&
                    <>
                        <Button color="primary" className="btn-sm mt-2" onClick={() => setModalAddFacilityCategory(!modalAddFacilityCategory)}>{t('facilities.category.add')}</Button>
                        <Modal isOpen={modalAddFacilityCategory} toggle={() => setModalAddFacilityCategory(!modalAddFacilityCategory)} centered size="xl">
                            <AddFacilityCategory modaltoggle={() => setModalAddFacilityCategory(!modalAddFacilityCategory)} refreshParent={(val) => onChange(val, name)} />
                        </Modal>
                    </>
                }
            </FormGroup>
        )
    } else {
        return null
    }
}

export default Select