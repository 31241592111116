import axios from "axios";

import env from "../env/src_config";
import { isNull } from "../izUtils";
import { axiosError } from "./response";

export const saveProjectSystem = async (headers, id, passedInputs, save) => {
    let payload = { save };

    if (!isNull(passedInputs)) {
        // Get data from inputs
        const keys = Object.keys(passedInputs);
        payload.data = {};
        const toArray = ['project_materials', 'project_log_materials'];
        const toRemove = ['method', 'system_id'];
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                payload.data[keys[i]] = passedInputs[keys[i]].value;
            } else {
                if (toArray.indexOf(keys[i]) !== -1) {
                    payload.data[keys[i]] = []
                } else if (toRemove.indexOf(keys[i]) !== -1) {
                    continue;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }
    }

    let url = env.api + '/api/project/security_system/' + id;
    const projectSystemResponse = await axios.patch(url, payload, {headers}).then(response => {
        return response.data
    }).catch(error => {
        axiosError(error);
    });

    return projectSystemResponse;
}