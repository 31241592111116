import React, { Fragment, useEffect, useState } from 'react'
import {t} from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil'
import axios from 'axios';
import { Accordion } from 'react-bootstrap';
import { Container, Card, CardBody, CardFooter, Button, Row, Col, Collapse } from 'reactstrap'
import Swal from 'sweetalert2';

import env from '../../env/src_config';
import { headersState, tokenState } from '../../recoil/recoil';
import { isNull } from '../../izUtils'
import Spinner from '../../components/spinner/Spinner'
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb'
import { transformer } from '../../helpers/fields';
import { axiosError, errorStatus } from '../../helpers/response';
import PaginationBottom from '../../components/Pagination/PaginationBottom';
import PaginationTop from '../../components/Pagination/PaginationTop';
import PhonebookList from '../../components/phonebook/list/PhonebookList';
import Filter from '../../components/filters/Filter';

const AllPhonebook = () => {
    const navigate = useNavigate();

    const headers = useRecoilValue(headersState);
    const token = useRecoilValue(tokenState);
    const [phonebook, setPhonebook] = useState(null)
    const [filters, setFilters] = useState(null)
    const [isFilterOpen, setIsFilterOpen] = useState(true);

    const lsFilterName = 'AKODA.phonebookFilter';

    useEffect(() => {
        const lsFilter = JSON.parse(localStorage.getItem(lsFilterName));
        getPhonebook(isNull(lsFilter) ? {} : lsFilter);

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getPhonebook = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }

        axios.post(env.api + '/api/phonebook/list', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setPhonebook(response.data.data);
                if (!isNull(response.data.filters)) {
                    setFilters(transformer(response.data.filters));
                    localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
                }
            }
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName)
        getPhonebook({});
    }

    const handleDelete = (row) => {
        Swal.fire({
            title: t('phonebook.list.delete.title'),
            text: t('phonebook.list.delete.text'),
            icon: 'warning',
            confirmButtonColor: 'var(--theme-default)',
            showCancelButton: true,
            confirmButtonText: t('ok'),
            cancelButtonText: t('cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(env.api + '/api/phonebook/' + row.id, {headers}).then((response) => {
                    if (response.data.state === 'success') {
                        getPhonebook(filters);
                        Swal.fire({
                            text: response.data.message,
                            icon: 'success',
                            confirmButtonColor: 'var(--theme-default)',
                            confirmButtonText: t('ok'),
                        })
                    } else {
                        errorStatus(response.data, t);
                    }
                }).catch(error => {
                    axiosError(error, navigate);
                });
            }
        })
    }

    if (isNull(phonebook)) {
        return <Spinner />
    } else {
        const filterFields = ['name']
        return (
            <Fragment>
                <Breadcrumbs parent="" title={t('breadcrumb.phonebook')} />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('phonebook.list.title')}</div>
                                    <div>
                                        <Accordion className='mb-3'>
                                            <div className="default-according style-1" id="phonebookfilter">
                                                <Button color="secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={() => setIsFilterOpen(!isFilterOpen)}>
                                                    {t('filters')}
                                                    <div className={'filter-icon' + (isFilterOpen ? ' rotate-icon' : '')}>
                                                        <i className="fa fa-chevron-down"></i>
                                                    </div>
                                                </Button>
                                                <Collapse isOpen={isFilterOpen}>
                                                    <div className='p-3'>
                                                        <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => getPhonebook(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </Accordion>
                                        <PaginationTop filters={filters} getData={clonedFilters => getPhonebook(clonedFilters)} />
                                        <PhonebookList data={phonebook} handleDelete={handleDelete} />
                                        <PaginationBottom filters={filters} getData={clonedFilters => getPhonebook(clonedFilters)} />
                                    </div>
                                </CardBody>
                                {(token.parsedToken.type === 'technician') &&
                                    <CardFooter>
                                        <div className='text-end'>
                                            <Button color="primary" className="mt-2 me-2" onClick={() => navigate('/phonebook/create')}>{t('phonebook.list.create')}</Button>
                                        </div>
                                    </CardFooter>
                                }
                            </Card>
                        </Col>
                    </Row>
                    <hr/>
                    <div className='d-flex flex-wrap justify-content-between mb-4'>
                        <div>
                            <Button color="danger" className="mt-2 me-2" onClick={() => navigate('/')}>{t('back')}</Button>
                        </div>
                    </div>
                </Container>
            </Fragment>
        )
    }
}

export default AllPhonebook