import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'
import { Container, Button, Form, Row, Col, Card, CardBody, CardFooter } from 'reactstrap'
import Swal from 'sweetalert2'

import env from '../../env/src_config'
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb'
import { headersState } from '../../recoil/recoil'
import { axiosError } from '../../helpers/response'
import { isNull } from '../../izUtils'
import Spinner from '../../components/spinner/Spinner'
import { errorStatus } from '../../helpers/response'
// import { getSingleItem } from '../../helpers/getSingleItem'
import DisplayFields from '../../helpers/displayFields'
import { transformer, linker } from '../../helpers/fields'

const CreateStandby = () => {
    const Navigate = useNavigate();
    const { standbyId, standbyMethod } = useParams()

    const headers = useRecoilValue(headersState);
    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        // if (standbyId === 'create' || (!isNull(standbyMethod) && standbyMethod === 'update')) {
            updateData(false, null);
        // } else {
        //     getSingleItem(headers, standbyId, '/api/standby/', Navigate).then(data => {
        //         if (!isNull(data)) {
        //             setInputs(data.fields);
        //         }
        //     })
        // }

    }, [standbyId, standbyMethod]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }

        }

        let method = 'post' // Create
        if (standbyId !== 'create' && (!isNull(standbyMethod) && standbyMethod === 'update')) method = 'patch' // Update
        axios[method](env.api + '/api/standby/' + standbyId, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => Navigate(-1))
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            dateHandler: (value, id) => textChange(value, id),
        }

        if (standbyMethod === 'get') {
            if (!isNull(inputs[field])) return <DisplayFields key={'display-'+inputs[field].name} data={inputs[field]} />
        } else {
            return linker(inputlinkerFields);
        }
    }

    return (
        <Fragment>
            <Breadcrumbs parent={t('breadcrumb.standby')} title={t('breadcrumb.standby')} />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="ribbon-wrapper">
                            <CardBody>
                                <div className="ribbon ribbon-clip ribbon-primary">{ (standbyId === 'create') ? t('standby.create.title') : ( standbyMethod === 'update' ? t('standby.update.title') : t('standby.get.title') ) }</div>
                                {isNull(inputs) ?
                                    <Spinner />
                                    :
                                    <Fragment>
                                        <Form className="theme-form">
                                            <Row>
                                                <Col sm="12" md="6">
                                                    { ['from', 'to', 'standby_technician_1', 'standby_technician_2', 'standby_technician_cns'].map(field => mapLinker(field)) }
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Fragment>
                                }
                            </CardBody>
                            {(standbyId === 'create' || (!isNull(standbyMethod) && (standbyMethod === 'update' || standbyMethod === 'draft'))) &&
                                <CardFooter>
                                    <div className='text-end'>
                                        <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{t('save')}</Button>
                                    </div>
                                </CardFooter>
                            }
                        </Card>
                    </Col>
                </Row>
                <div className='d-flex flex-wrap justify-content-between mb-4'>
                    <Button color="danger" className="mt-2 me-2" onClick={() => Navigate(-1)}>{t('back')}</Button>
                </div>
            </Container>
        </Fragment>
    )
}

export default CreateStandby