import Swal from "sweetalert2";
import { isNull } from "../izUtils";

export const axiosError = (error, navigate) => {
    console.log(error)
    if (!isNull(error.response.data.message)) {
        alert(error.response.data.message)
    } else if (!isNull(error.response.data.error)) {
        alert(error.response.data.error)
    }

    if (error.response.data.code === 'unauthorized_access') {
        if (!isNull(navigate)) navigate('/');
    } else if (error.response.data.code === 'invalid_jwt') {
        document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        window.location.reload();
    }
}

export const errorStatus = (responseData, t) => {
    console.log(responseData)
    let errorTxt = '';
    if (!isNull(responseData.data)) {
        for (let i = 0; i < responseData.data.length; i++) {
            if (!isNull(responseData.data[i].errors)) {
                errorTxt += '<br /><b>' + responseData.data[i].title + '</b>: ' + responseData.data[i].errors.map(error => error).join('<br />');
            }
        }
    }
    if (errorTxt.length === 0 && !isNull(responseData.error)) errorTxt = responseData.error;
    if (errorTxt.length === 0 && !isNull(responseData.message)) errorTxt = responseData.message;

    Swal.fire({
        title: t('error'),
        html: errorTxt,
        icon: 'error',
        confirmButtonColor: 'var(--theme-default)',
        confirmButtonText: t('ok')
    });
}

export const errorStatusDraft = (responseData, t, errorException) => {
    console.log(responseData)
    let errorTxt = '';
    if (!isNull(responseData.data)) {
        for (let i = 0; i < responseData.data.length; i++) {
            if (!isNull(responseData.data[i].errors)) {
                if (isNull(errorException) || ( !isNull(errorException) && errorException.indexOf(responseData.data[i].name) === -1 )) {
                    errorTxt += '<br /><b>' + responseData.data[i].title + '</b>: ' + responseData.data[i].errors.map(error => error).join('<br />');
                }
            }
        }
    }

    if (errorTxt.length !== 0) {
        Swal.fire({
            title: t('error'),
            html: errorTxt,
            icon: 'error',
            confirmButtonColor: 'var(--theme-default)',
            confirmButtonText: t('ok')
        });
    }

    return errorTxt.length === 0
}