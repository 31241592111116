import React, { useEffect, useState, useRef } from 'react'
import { t } from 'i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Row, Col, Card, CardHeader, CardBody, Collapse, Button } from 'reactstrap'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

import env from '../../env/src_config';
import { headersState, tokenState, multiSelectLoading, materialLoading, serviceDraft } from '../../recoil/recoil';
import { isNull } from '../../izUtils';
import { linker, transformer, transformerForPublic } from '../../helpers/fields';
import DisplayFields from '../../helpers/displayFields';
import { getSingleItem } from '../../helpers/getSingleItem';
import { axiosError, errorStatus } from '../../helpers/response';
import Spinner from '../spinner/Spinner';
import ServiceTechnicians from './list/ServiceTechnicians';
import Loader from '../spinner/Loader';
import { getServiceHistory } from '../../helpers/getServiceHistory';

const ServiceItem = ({service, index, toggle, isOpen, permissions, type, getPriceData}) => {
    const navigate = useNavigate();

    const headers = useRecoilValue(headersState);
    const tokenData = useRecoilValue(tokenState);
    const setMultiLoading = useSetRecoilState(multiSelectLoading);
    const setMaterialLoading = useSetRecoilState(materialLoading);
    const setServiceDraft = useSetRecoilState(serviceDraft);
    const [inputs, setInputs] = useState(null)
    const [history, setHistory] = useState(null)
    const [showErrors, setShowErrors] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [loading, setLoading] = useState(false);

    const serviceData = useRef({});

    const lsTechicians = 'AKODA.serviceTechnicians-'+service.id;
    const lsTaskInputs = 'AKODA.taskInputs';

    useEffect(() => {
        if (!isNull(type) && type === 'public') { // This is for public url (for task approval)
            setInputs(transformerForPublic(service.data));
        } else {
            if (!isNull(permissions) && permissions['service.update']) {
                updateData(false, null);
            } else {
                getSingleItem(headers, service.id, '/api/service/', navigate).then(data => {
                    if (!isNull(data)) {
                        setInputs(data.fields);
                        serviceData.current = data.otherData;
                        if (service.draft) setServiceDraft(prev => { // Save draft values to global state
                            return {...prev, values: data.fields}
                        })
                    }
                })
            }
        }

        return () => {
            localStorage.removeItem(lsTechicians)
        }
    }, [service.id]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (save) {
            setLoading(true);
        }

        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }

            // Get data from localStorage
            let selectedTechnicians = JSON.parse(localStorage.getItem(lsTechicians))
            if (!isNull(selectedTechnicians)) {
                if (isNull(payload.data)) payload.data = {};
                payload.data.technicians = selectedTechnicians.map(technician => {
                    return {
                        id: technician.id,
                        from: technician.from,
                        to: technician.to,
                        programming_work: technician.programming_work,
                        remote_work: technician.remote_work,
                    }
                })
            }
        }

        axios.patch(env.api + '/api/service/' + service.id, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.valid) && response.data.valid) {
                    Swal.fire({
                        title:  t('saved'),
                        text:  response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    })/*.then(() => {
                        toggle(index);
                    })*/

                    if (tokenData.parsedToken.type === 'admin' || tokenData.parsedToken.type === 'moderator') {
                        getPriceData();
                    }
                } else {
                    errorStatus(response.data, t);
                    setShowErrors(true);
                }

                setLoading(false)
            }

            serviceData.current = response.data;
            const transformedResponse = transformer(response.data.data);
            if (!isNull(response.data.data)) setInputs(transformedResponse);

            if (service.draft) setServiceDraft(prev => { // Save draft values to global state
                return {...prev, values: transformedResponse}
            })

            if (!isNull(response.data.history)) {
                getServiceHistory(headers, '/api/service/'+service.id+'/'+response.data.history, navigate).then(data => {
                    setHistory(data);
                })
            }

            setMultiLoading(false) // Set loading for field MultiSelect to false
            setMaterialLoading(false) // Set loading for field Materials to false
        }).catch(error => {
            setLoading(false);
            axiosError(error, navigate);
        });
    }

    const handlePdf = () => {
        let pdfTitle = 'service-' + (serviceData.current?.service_number) + '.pdf';
        let taskInputs = JSON.parse(localStorage.getItem(lsTaskInputs));
        if (!isNull(taskInputs)) {
            taskInputs.facility.values.forEach((facility) => {
                if (facility.id === taskInputs.facility.value) {
                    const formattedIndex = (serviceData.current?.service_number).toString().padStart(3, '0');
                    const invalidCharsRegex = "[/\\\\:*?\"<>|\\x00]";

                    let sanitizedTitle = facility.title.replaceAll(invalidCharsRegex, "");
                    sanitizedTitle = sanitizedTitle.replaceAll(" ", "");
                    pdfTitle = sanitizedTitle + "_" + formattedIndex + ".pdf";
                }
            });
        }

        setLoadingPdf(true)
        let url = '/api/service/pdf/';
        if (!isNull(type) && type === 'public') url = '/api/service/public/pdf/';
        axios.get(env.api + url + service.id, {headers, responseType: 'blob'}).then(response => {
            if (response.data.state !== 'error') {
                if (response.data.type === 'application/pdf') {
                    const href = window.URL.createObjectURL(response.data);
                    const a = document.createElement('a');
                    a.download = pdfTitle;
                    a.href = href;
                    a.click();
                    a.href = '';
                } else {
                    Swal.fire({
                        title:  t('error'),
                        text:  t('file.download.error.wrongType'),
                        icon: 'error',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    })
                }
            }
            setLoadingPdf(false)
        }).catch(error => {
            setLoadingPdf(false)
            axiosError(error, navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    const multiChange = (value, name, component) => {
        setMultiLoading(name + '-' + component?.id)
        let clonedInputs = {...inputs};

        if (clonedInputs[name].value.length === 0) {
            clonedInputs[name].value.push(value)
        } else {
            if (clonedInputs[name].value.indexOf(value) === -1) {
                clonedInputs[name].value.push(value);
            } else {
                const filteredValues = clonedInputs[name].value.filter(el => el !== value);
                clonedInputs[name].value = filteredValues;
            }
        }

        updateData(false, clonedInputs);
    }

    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            component: service,
            history: history,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            multiHandler: (value, id, component) => multiChange(value, id, component),
            booleanHandler: (value, id) => textChange(value, id),
            dateHandler: (value, id) => textChange(value, id),
        }

        if (isNull(permissions) || !permissions['service.update']) {
            if (!isNull(inputs[field])) return <DisplayFields key={'display-'+inputs[field].name} data={inputs[field]} />
        } else {
            return linker(inputlinkerFields);
        }
    }

    if (!service.draft) {
        return (
            <Card>
                <CardHeader className={service.draft ? "bg-warning" : "bg-primary"}>
                    <h5 className="mb-0">
                        <Button className='btn btn-link acc-btn ps-0 d-flex justify-content-between align-items-center' color='default' onClick={() => toggle(index)}>
                            <div>{index+1}.&nbsp;{t('service.single.title') + (service.draft ? ' - ' + t('service.single.draft') : '')}</div>
                            <div className={'filter-icon' + (isOpen.indexOf(index) !== -1 ? ' rotate-icon' : '')}>
                                <i className="fa fa-chevron-down" style={{ position: 'relative', top: '0px', left: '0px', fontSize: '14px' }}></i>
                            </div>
                        </Button>
                    </h5>
                </CardHeader>
                <Collapse isOpen={isOpen.indexOf(index) !== -1}>
                    {isNull(inputs) ?
                        <Spinner />
                        :
                        <CardBody style={{ position: 'relative' }}>
                            {loading && <Loader />}
                            <Row>
                                <Col md="12">
                                    {(type !== 'public') &&
                                        <Row>
                                            <Col md="6">
                                                { ['technician_count'].map(field => mapLinker(field)) }
                                            </Col>
                                            <Col md="6">
                                                { ['vehicle_count'].map(field => mapLinker(field)) }
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <ServiceTechnicians
                                        data={inputs.technicians}
                                        saveToLS={lsTechicians}
                                        showErrors={showErrors}
                                        getData={() => updateData(false, inputs)}
                                        type={type}
                                        history={isNull(history)
                                            ? null
                                            : {
                                                changed_at: history?.changed_at,
                                                changed_by: history?.changed_by,
                                                field: history?.fields['technicians']
                                            }
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    { ['work_description', 'security_systems', 'signature_status', 'signature_notes', 'signatory_name', 'signature'].map(field => mapLinker(field)) }
                                </Col>
                                <Col md="6">
                                    { ['service_type', 'service_type_other', 'submitted_at', 'materials', 'work_incomplete'].map(field => mapLinker(field)) }
                                </Col>
                            </Row>
                            <hr/>
                            <div className='d-flex justify-content-between'>
                                {loadingPdf ?
                                    <div className="loader-box justify-content-start ms-4" style={{ height: 'auto' }}><div className="loader-3" style={{ height: '27px', width: '27px' }}></div></div>
                                    :
                                    !service.draft && <Button color="primary" className="me-2" onClick={handlePdf}>{t('service.single.downloadPdf')}</Button>
                                }
                                {(!isNull(permissions) && permissions['service.update']) &&
                                    <Button color="primary" className="me-2" onClick={() => updateData(true, inputs)}>{t('save')}</Button>
                                }
                            </div>
                        </CardBody>
                    }
                </Collapse>
            </Card>
        )
    }
}

export default ServiceItem