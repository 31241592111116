import { useState, useEffect, useRef } from 'react';

export default function useComponentVisible(initialIsVisible, selectInputId = '', selectDeleteId = '') {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        } else {
            // Don't close dropdown when text input or delete button (and icon inside the button) is clicked
            const delteIcon = document.getElementById(selectDeleteId);
            if ((event.target.id !== selectInputId) && (event.target.id !== selectDeleteId) && (!delteIcon?.contains(event.target))) {
                setIsComponentVisible((prev) => !prev);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return { ref, isComponentVisible, setIsComponentVisible };
}