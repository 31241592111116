import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup, Label, Input } from 'reactstrap'

import { customStyles } from '../../../izUtils'

const UserList = ({data, selectedUsers, setSelectedUsers}) => {
    const navigate = useNavigate();

    const tableColumns = useMemo(() => {
        return [
            {
                name: '',
                width: '50px',
                cell: row => {
                    return (
                        <div className="custom-control custom-checkbox form-check">
                            <div className="form-checkbox-group">
                                <Input
                                    className={"custom-control-input"}
                                    id={row.id}
                                    type="checkbox"
                                    onChange={() => handleRowSelected(row)} checked={selectedUsers.indexOf(row.id) !== -1}
                                />
                                <Label className="checkmark secondary" htmlFor={row.id}></Label>
                            </div>
                        </div>
                    )
                }
            },
            {
                name: t('table.email'),
                selector: row => row.email,
                sortable: true,
                cell: row => {
                    if (selectedUsers.includes(row.id)) return <div className="clickableRow" onClick={() => handleRowSelected(row)}>{row.email}</div>
                    return row.email
                }
            },
            {
                name: t('table.name'),
                selector: row => row.name,
                sortable: true,
                cell: row => {
                    if (selectedUsers.includes(row.id)) return <div className="clickableRow" onClick={() => handleRowSelected(row)}>{row.name}</div>
                    return row.name
                }
            },
        ]
    }, [selectedUsers]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleRowSelected = (row) => {
        navigate('/schedule/exception/create/')

        setSelectedUsers(prev => (
            prev.includes(row.id)
                ? prev.filter(id => id !== row.id)
                : [...prev, row.id]
        ));
    }

    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowSelected}
            />
        </FormGroup>
    )
}

export default UserList