import React, { useState, useEffect, forwardRef, Fragment, useImperativeHandle, useMemo, useRef } from 'react'
import { t } from 'i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { Row, Col, Card, CardBody, Form, Button, ListGroup, ListGroupItem, Table } from 'reactstrap'
import axios from 'axios'
import { useRecoilValue } from 'recoil'
import Swal from 'sweetalert2'

import env from '../../../env/src_config'
import { headersState, tokenState } from '../../../recoil/recoil'
import Spinner from '../../spinner/Spinner'
import { isNull, number_format } from '../../../izUtils'
import { errorStatus, axiosError } from '../../../helpers/response'
import TaskPriceItem from './TaskPriceItem'
import Loader from '../../spinner/Loader'
import { linker } from '../../../helpers/fields'

const TaskPrices = forwardRef(({taskData, data, type}, ref) => {
    const navigate = useNavigate();
    const {id, method} = useParams();
    const headers = useRecoilValue(headersState);
    const token = useRecoilValue(tokenState);

    const [loadingItems, setLoadingItems] = useState(false); // Loader for materials
    const [loadingPrice, setLoadingPrice] = useState(false); // Loader for the entire component
    const [showErrors, setShowErrors] = useState(false);
    const [inputs, setInputs] = useState(null);

    const partsDiscount = useRef(null)

    // The component instance will be extended with whatever you return from the callback passed as the second argument
    useImperativeHandle(ref, () => {
        return {
            getTaskPricesInputs() {
                return inputs
            },
            updatePrices(save, passedInputs, use_service_materials) {
                getData(save, passedInputs, use_service_materials);
            }
        }
    }, [inputs]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isNull(type) && type === 'public') {
            if (!isNull(data)) transformData(data)
        } else {
            getData(false, null, false);
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getData = (save, passedInputs, use_service_materials) => {
        let payload = { save, use_service_materials };
        if (save) {
            setLoadingPrice(true);
        } else if (use_service_materials) {
            setLoadingItems(true);
        }

        if (!isNull(passedInputs)) {
            payload.data = {};
            const inputKeys = Object.keys(passedInputs);

            for (let i = 0; i < inputKeys.length; i++) {
                if (inputKeys[i] === 'items') {
                    const keys = ['name', 'quantity', 'price_per_unit', 'discount', 'warranty'];
                    const toInt = ['quantity', 'discount'];
                    const toFloat = ['price_per_unit'];

                    let transformedItems = passedInputs[inputKeys[i]].value.map(value => {
                        let item = {};

                        for (let i = 0; i < keys.length; i++) {
                            if (!isNull(value[keys[i]]) && value[keys[i]].length !== 0) {
                                if (toFloat.indexOf(keys[i]) !== -1) {
                                    let val = value[keys[i]].replace(/\./g, '').replace(',', '.');
                                    item[keys[i]] = val
                                } else if (toInt.indexOf(keys[i]) !== -1) {
                                    let val = value[keys[i]]+'';
                                    val = val.replace(/\./g, '').split(',');
                                    item[keys[i]] = val[0]
                                } else {
                                    item[keys[i]] = value[keys[i]]
                                }
                            } else {
                                if (toFloat.indexOf(keys[i]) !== -1) {
                                    item[keys[i]] = "0.00";
                                } else if (toInt.indexOf(keys[i]) !== -1) {
                                    item[keys[i]] = "0";
                                } else {
                                    item[keys[i]] = "";
                                }
                            }
                        }

                        return item
                    });

                    payload.data[inputKeys[i]] = transformedItems;
                } else {
                    if (!isNull(passedInputs[inputKeys[i]].value) && passedInputs[inputKeys[i]].value.length !== 0) {
                        payload.data[inputKeys[i]] = passedInputs[inputKeys[i]].value;
                    } else {
                        payload.data[inputKeys[i]] = "";
                    }
                }
            }
        }

        let request = 'get'
        let params = {headers}
        if ((method !== 'get') && (taskData.permissions['prices.update'])) {
            request = 'patch';
            params = payload
        }

        // If there is only one element in array -> element name is empty and element prices are 0 -> remove that element
        if (save && payload.data.items.length === 1) {
            if (payload.data.items[0].name === '' && payload.data.items[0].quantity === '0' && payload.data.items[0].price_per_unit === '0.00'/* && payload.data.items[0].discount === '0'*/) {
                payload.data.items = [];
            }
        }

        axios[request](env.api + '/api/task/prices/' + id, params, (request === 'patch' ? {headers} : null)).then(response => {
            partsDiscount.current = response.data.parts_discount;
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title:  t('saved'),
                        text:  response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    })
                    // If prices are successfully updated call BE again to get updated worklog values
                    getData(false, inputs, false);
                } else {
                    errorStatus(response.data, t);
                    setShowErrors(true);
                }

                setLoadingPrice(false);
            } else {
                if (!isNull(response.data.data)) transformData(response.data.data)
            }
            setLoadingItems(false);
        }).catch(error => {
            setLoadingItems(false);
            setLoadingPrice(false);
            axiosError(error, navigate);
        });
    }

    const transformData = (responseData) => {
        let data = {};
        responseData.forEach(field => {
            if (field.name === "items") {
                data[field.name] = field;

                if (isNull(data[field.name].value) || data[field.name].value.length === 0) {
                    if (method !== 'get' && type !== 'public') {
                        data[field.name].value = [{
                                name: "",
                                quantity: "0",
                                price_per_unit: "0,00",
                                discount: partsDiscount.current+'',
                                price_total: "0,00",
                                warranty: false,
                        }]
                    }
                } else {
                    const keys = Object.keys(responseData[0].value[0]);
                    data[field.name].value = responseData[0].value.map(value => {
                        const toFloat = ['price_per_unit'];
                        let item = {};
                        for (let i = 0; i < keys.length; i++) {
                            if (!isNull(value[keys[i]]) && value[keys[i]].length !== 0 && toFloat.indexOf(keys[i]) !== -1) {
                                item[[keys[i]]] = number_format(value[keys[i]], 2, ',', '.');
                            } else {
                                item[[keys[i]]] = value[keys[i]]
                            }
                        }
                        return item
                    });
                }
            } else {
                data[field.name] = field;
            }
        })

        setInputs(data)
    }

    const handleItem = (item, index) => {
        let clonedInputs = {...inputs}

        if (isNull(item)) {
            setLoadingItems(true)
            clonedInputs.items.value = clonedInputs.items.value.filter((el, i) => i !== index)
        } else {
            clonedInputs.items.value[index] = item
        }

        getData(false, clonedInputs, false)
    }

    const addPrice = () => {
        setLoadingItems(true)
        let clonedInputs = {...inputs}
        let newValues = clonedInputs.items.value;
        newValues.push({ name: "", quantity: "0", price_per_unit: "0", discount: partsDiscount.current+'', price_total: "0", warranty: false })
        clonedInputs.items.value = newValues;
        getData(false, clonedInputs, false)
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        getData(false, clonedInputs, false)
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    const userType = useMemo(() => {
        return token.parsedToken.type;
    }, [token.parsedToken.type])

    if (isNull(inputs)) {
        return <Spinner />
    } else {
        let taskInputs = JSON.parse(localStorage.getItem('AKODA.taskInputs'));
        let totalTotal = 0;
        if (isNull(taskInputs?.quotation_pricing?.value) || !taskInputs?.quotation_pricing?.value) {
            totalTotal = (
                ( inputs?.travel_cost?.value?.total ? parseFloat(inputs.travel_cost.value.total) : 0 ) +
                ( inputs?.travel_fixed_expenses?.value?.travel_expenses ? parseFloat(inputs.travel_fixed_expenses.value.travel_expenses) : 0 ) +
                ( inputs?.worklog?.value?.working_hour?.total ? parseFloat(inputs.worklog.value.working_hour.total) : 0 ) +
                ( inputs?.worklog?.value?.overtime?.total ? parseFloat(inputs.worklog.value.overtime.total) : 0 ) +
                ( inputs?.worklog?.value?.night_overtime?.total ? parseFloat(inputs.worklog.value.night_overtime.total) : 0) +
                ( inputs?.worklog?.value?.sunday_overtime?.total ? parseFloat(inputs.worklog.value.sunday_overtime.total) : 0 ) +
                ( inputs?.worklog?.value?.programming?.total ? parseFloat(inputs.worklog.value.programming.total) : 0 ) +
                ( inputs?.fixed_check_price?.value?.total ? parseFloat(inputs.fixed_check_price.value.total) : 0 )
            )

            if (inputs.items?.value && inputs.items.value.length !== 0) {
                inputs.items.value.forEach(val => totalTotal += parseFloat(val.price_total))
            }
        } else {
            totalTotal = taskInputs?.quotation_net_price?.value;
        }

        return (
            <Card>
                <CardBody>
                    {loadingPrice && <Loader />}
                    {(isNull(taskInputs?.quotation_pricing?.value) || !taskInputs?.quotation_pricing?.value) ?
                        <>
                            {/* Items - material */}
                            <div style={{ position: 'relative' }}>
                                {loadingItems && <Loader />}
                                {!isNull(inputs.items.value) && inputs.items.value.length !== 0 && <h5>{inputs.items.title}</h5>}
                                {( (method !== 'get') && (taskData.permissions['prices.update']) ) &&
                                    <Button color="primary" className="mt-2 mb-3 me-2" onClick={() => getData(false, null, true)}>{t('task.prices.items.useServiceMaterials')}</Button>
                                }
                                <Form className="theme-form">
                                    <Col sm="12">
                                        {!isNull(inputs.items.value) && inputs.items.value.length !== 0 &&
                                            inputs.items.value.map((item, index) => (
                                                <TaskPriceItem key={"task-price-"+index} index={index} item={item} handleItem={handleItem} permissions={taskData.permissions} showCheckbox={inputs.warranty.value} />
                                            ))
                                        }
                                    </Col>
                                </Form>
                                {( (method !== 'get') && (taskData.permissions['prices.update']) ) &&
                                    <Button color="primary" className="mt-2 me-2" onClick={addPrice}>{t('task.prices.items.addNew')}</Button>
                                }
                            </div>
                            {/* Prices form */}
                            {( (method !== 'get') && (taskData.permissions['prices.update']) ) &&
                                <Fragment>
                                    <hr/>
                                    <h5>{t('task.prices.adjusted.title')}</h5>
                                    <Form className="theme-form">
                                        <Row>
                                            <Col sm="6">
                                                { ['adjusted_working_hour', 'adjusted_overtime', 'adjusted_night_overtime', 'adjusted_sunday_overtime', 'adjusted_programming_price'].map(field => mapLinker(field)) }
                                            </Col>
                                            <Col sm="6">
                                                { ['adjusted_mileage_price', 'adjusted_travel_price', 'adjusted_fixed_check_price', 'adjusted_travel_fixed_expenses'].map(field => mapLinker(field)) }
                                                { ['warranty_working_hour', 'warranty_overtime', 'warranty_night_overtime', 'warranty_sunday_overtime', 'warranty_programming '].map(field => mapLinker(field)) }
                                            </Col>
                                        </Row>
                                    </Form>
                                    <div className='text-end'>
                                        <Button color="primary" className="mt-2 mb-3 me-2" onClick={() => getData(true, inputs, false)}>{t('task.prices.adjusted.save')}</Button>
                                    </div>
                                </Fragment>
                            }
                            {/* Travel expenses, worklog and fixed_check_price */}
                            {((!isNull(inputs.travel_fixed_expenses.value) && inputs.travel_fixed_expenses.value.length !== 0) || (!isNull(inputs.travel_cost.value) && inputs.travel_cost.value.length !== 0)) &&
                                <Fragment>
                                    <hr/>
                                    <h5>{t('task.prices.travel.title')}</h5>
                                    <ListGroup className="list-group-flush">
                                        <ListGroupItem>
                                            {/* travel_fixed_expenses */}
                                            {(!isNull(inputs.travel_fixed_expenses.value) && inputs.travel_fixed_expenses.value.length !== 0) &&
                                                <div className={(inputs.adjusted_travel_fixed_expenses.value.length !== 0 && userType === 'accountant') ? 'red-text mb-1' : 'mb-1'}>
                                                    <b>{t('task.prices.travel.travel_fixed_expenses')}:</b>&nbsp;{number_format(inputs.travel_fixed_expenses.value.travel_expenses, 2, ',', '.')}€
                                                </div>
                                            }
                                            {/* travel_cost */}
                                            {(!isNull(inputs.travel_cost.value) && inputs.travel_cost.value.length !== 0) &&
                                                <Fragment>
                                                    <div className="project-box mb-3">
                                                        <div className='mb-5'>
                                                            <h6>{t('task.prices.travel.mileage.title')}</h6>
                                                            <Col sm="12" className='mb-1'>
                                                                <div className="table-responsive">
                                                                    <Table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">{t('task.prices.travel.mileage.facility_mileage')}</th>
                                                                                <th scope="col">{t('task.prices.travel.mileage.vehicle_count')}</th>
                                                                                <th scope="col">{t('task.prices.travel.mileage.trips')}</th>
                                                                                <th scope="col">{t('task.prices.travel.mileage.mileage_price')}</th>
                                                                                <th scope="col">{t('together')}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{inputs.travel_cost.value.facility_mileage * 2}</td>
                                                                                <td>{inputs.travel_cost.value.vehicle_count}</td>
                                                                                <td>{inputs.travel_cost.value.trips}</td>
                                                                                <td className={(inputs.adjusted_mileage_price.value.length !== 0 && userType === 'accountant') ? 'red-text' : ''}>{(!isNull(inputs.travel_cost.value.mileage_price)) && number_format(inputs.travel_cost.value.mileage_price, 2, ',', '.')}</td>
                                                                                <td> {(!isNull(inputs.travel_cost.value.milage_total)) && number_format(inputs.travel_cost.value.milage_total, 2, ',', '.')}€</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                        <div className='mt-4'>
                                                            <h6>{t('task.prices.travel.travelTime.title')}</h6>
                                                            <Col sm="12" className='mb-1'>
                                                                <div className="table-responsive">
                                                                    <Table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">{t('task.prices.travel.travelTime.travel_time')}</th>
                                                                                <th scope="col">{t('task.prices.travel.travelTime.travel_price')}</th>
                                                                                <th scope="col">{t('task.prices.travel.travelTime.technicians')}</th>
                                                                                <th scope="col">{t('together')}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>{number_format(inputs.travel_cost.value.travel_time * 2 / 60, 2, ',', '.')}</td>
                                                                                <td className={(inputs.adjusted_travel_price.value.length !== 0 && userType === 'accountant') ? 'red-text' : ''}>{inputs.travel_cost.value.travel_price}</td>
                                                                                <td>{inputs.travel_cost.value.technicians}</td>
                                                                                <td> {(!isNull(inputs.travel_cost.value.travel_time_total)) && number_format(inputs.travel_cost.value.travel_time_total, 2, ',', '.')}€</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }
                                            <b>{t('together')}:</b>&nbsp;
                                            { number_format( ( !isNull(inputs.travel_cost.value.total) ? inputs.travel_cost.value.total : 0 ) + ( !isNull(inputs.travel_fixed_expenses.value.travel_expenses) ? inputs.travel_fixed_expenses.value.travel_expenses : 0 ), 2, ',', '.' ) }€
                                        </ListGroupItem>
                                    </ListGroup>
                                </Fragment>
                            }
                            {/* worklog */}
                            {(!isNull(inputs.worklog.value) && inputs.worklog.value.length !== 0) &&
                                <Fragment>
                                    <hr/>
                                    <h5>{t('task.prices.workLog.title')}</h5>
                                    <ListGroup className="list-group-flush">
                                        <ListGroupItem>
                                            <div className='project-box mb-3'>
                                                <Col sm="12">
                                                    <div className="table-responsive">
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col"></th>
                                                                    <th scope="col">{t('task.prices.workLog.hours')}</th>
                                                                    <th scope="col">{t('task.prices.workLog.price')}</th>
                                                                    <th scope="col">{t('together')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th>{t('task.prices.workLog.workingHour')}</th>
                                                                    <td>{number_format(inputs.worklog.value.working_hour.hours, 2, ',', '.')}</td>
                                                                    <td className={inputs.worklog.value.working_hour.price_changed ? 'red-text' : ''}>{number_format(inputs.worklog.value.working_hour.price_per_hour, 2, ',', '.')}</td>
                                                                    <td>{number_format(inputs.worklog.value.working_hour.total, 2, ',', '.')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{t('task.prices.workLog.overtime')}</th>
                                                                    <td>{number_format(inputs.worklog.value.overtime.hours, 2, ',', '.')}</td>
                                                                    <td className={inputs.worklog.value.overtime.price_changed ? 'red-text' : ''}>{number_format(inputs.worklog.value.overtime.price_per_hour, 2, ',', '.')}</td>
                                                                    <td>{number_format(inputs.worklog.value.overtime.total, 2, ',', '.')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{t('task.prices.workLog.night_overtime')}</th>
                                                                    <td>{number_format(inputs.worklog.value.night_overtime.hours, 2, ',', '.')}</td>
                                                                    <td className={inputs.worklog.value.night_overtime.price_changed ? 'red-text' : ''}>{number_format(inputs.worklog.value.night_overtime.price_per_hour, 2, ',', '.')}</td>
                                                                    <td>{number_format(inputs.worklog.value.night_overtime.total, 2, ',', '.')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{t('task.prices.workLog.sunday_overtime')}</th>
                                                                    <td>{number_format(inputs.worklog.value.sunday_overtime.hours, 2, ',', '.')}</td>
                                                                    <td className={inputs.worklog.value.sunday_overtime.price_changed ? 'red-text' : ''}>{number_format(inputs.worklog.value.sunday_overtime.price_per_hour, 2, ',', '.')}</td>
                                                                    <td>{number_format(inputs.worklog.value.sunday_overtime.total, 2, ',', '.')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{t('task.prices.workLog.programming')}</th>
                                                                    <td>{number_format(inputs.worklog.value.programming.hours, 2, ',', '.')}</td>
                                                                    <td className={inputs.worklog.value.programming.price_changed ? 'red-text' : ''}>{number_format(inputs.worklog.value.programming.price_per_hour, 2, ',', '.')}</td>
                                                                    <td>{number_format(inputs.worklog.value.programming.total, 2, ',', '.')}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </div>
                                            <b>{t('together')}:</b>&nbsp;{number_format(inputs.worklog.value.working_hour.total + inputs.worklog.value.overtime.total + inputs.worklog.value.night_overtime.total + inputs.worklog.value.sunday_overtime.total + inputs.worklog.value.programming.total, 2, ',', '.')}€
                                        </ListGroupItem>
                                    </ListGroup>
                                </Fragment>
                            }
                            {/* fixed_check_price */}
                            {(!isNull(inputs.fixed_check_price.value) && inputs.fixed_check_price.value.length !== 0) &&
                                <Fragment>
                                    <hr/>
                                    <h5>{t('task.prices.fixed_check_price.title')}</h5>
                                    <ListGroup className="list-group-flush">
                                        <ListGroupItem>
                                            {(!isNull(inputs.fixed_check_price.value.price_per_check)) &&
                                                <div className={(inputs.adjusted_fixed_check_price.value.length !== 0 && userType === 'accountant') ? 'red-text mb-1' : 'mb-1'}>
                                                    <b>{t('task.prices.fixed_check_price.price_per_check')}:</b>&nbsp;{number_format(inputs.fixed_check_price.value.price_per_check, 2, ',', '.')}€
                                                </div>
                                            }
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <b>{t('together')}:</b>&nbsp;{number_format((!isNull(inputs.fixed_check_price.value.total) ? inputs.fixed_check_price.value.total : 0), 2, ',', '.')}€
                                        </ListGroupItem>
                                    </ListGroup>
                                </Fragment>
                            }
                        </>
                        :
                        <div>
                            <b>{t('Po ponudbi')}:</b>&nbsp;
                            { number_format(taskInputs.quotation_net_price.value, 2, ',', '.') }€
                        </div>
                    }
                    {/* Together together */}
                    <div>
                        <hr/>
                        <h5 style={{ textTransform: 'uppercase' }}>{t('together')}</h5>
                        {number_format( totalTotal, 2, ',', '.')}€
                    </div>
                </CardBody>
            </Card>
        )
    }
})

export default TaskPrices