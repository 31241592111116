import Desktop from '../pages/Desktop/Desktop';
import CreateTask from '../pages/Tasks/CreateTask';
import CreateTaskReported from '../pages/Tasks/CreateTaskReported';
import Users from '../pages/Users/Users';
import Clients from '../pages/Clients/Clients';
import Facilities from '../pages/Facilities/Facilities';
import AllFacilities from '../pages/Facilities/AllFacilities';
import SecuritySystems from '../pages/SecuritySystems/SecuritySystems';
import Contracts from '../pages/Contracts/Contracts';
import AllContracts from '../pages/Contracts/AllContracts';
import Archive from '../pages/Archive/Archive';
import Mail from '../pages/Mail/Mail';
import MailSingle from '../pages/Mail/MailSingle';
// import Schedule from '../pages/Schedule/Schedule';
import ScheduleUsers from '../pages/Schedule/ScheduleUsers';
import Exception from '../pages/Schedule/Exception';
import CreateEvent from '../pages/Schedule/CreateEvent';
import UserProfile from '../pages/User/UserProfile';
import SignService from '../pages/Services/SignService'
import DefaultPrices from '../pages/Prices/DefaultPrices'
import CreatePrice from '../pages/Prices/CreatePrice';
import TaskApprove from '../auth/TaskApprove';
import Statistic from '../pages/Statistic/Statistic';
import AllProjects from '../pages/Projects/AllProjects';
import Projects from '../pages/Projects/Projects';
import ProjectsLog from '../pages/Projects/ProjectsLog';
import AllPhonebook from '../pages/Phonebook/AllPhonebook';
import Phonebook from '../pages/Phonebook/Phonebook';
import Standby from '../pages/Schedule/Standby';
import CreateStandby from '../pages/Schedule/CreateStandby';

export const routes = [
    { path: `${process.env.PUBLIC_URL}/`, Component: <Desktop /> },

    // Task and services
    { path: `${process.env.PUBLIC_URL}/task/approve/:token`, Component: <TaskApprove /> },
    { path: `${process.env.PUBLIC_URL}/task/:id/:method?`, Component: <CreateTask /> },
    { path: `${process.env.PUBLIC_URL}/task/create-reported`, Component: <CreateTaskReported /> },
    { path: `${process.env.PUBLIC_URL}/task/:taskId/service-sign/:serviceId`, Component: <SignService /> },

    // Clients
    { path: `${process.env.PUBLIC_URL}/clients/:clientId?`, Component: <Clients /> },
    { path: `${process.env.PUBLIC_URL}/clients/:clientId/facilities/:facilityId`, Component: <Facilities /> },
    { path: `${process.env.PUBLIC_URL}/clients/:clientId/facilities/:facilityId/linked-systems/:linkedSystemId`, Component: <Facilities /> },
    { path: `${process.env.PUBLIC_URL}/clients/:clientId/facilities/:facilityId/linked-systems/:linkedSystemId/contracts/:contractId/:contractMethod?`, Component: <Contracts /> },

    // Facilities
    { path: `${process.env.PUBLIC_URL}/facilities`, Component: <AllFacilities /> },

    // Contracts
    { path: `${process.env.PUBLIC_URL}/contracts`, Component: <AllContracts /> },
    { path: `${process.env.PUBLIC_URL}/contracts/:contractId/:contractMethod?`, Component: <Contracts /> },

    // Systems
    { path: `${process.env.PUBLIC_URL}/security-systems`, Component: <SecuritySystems /> },
    { path: `${process.env.PUBLIC_URL}/security-systems/:securitySystemId`, Component: <SecuritySystems /> },
    { path: `${process.env.PUBLIC_URL}/security-systems/:securitySystemId/manufacturers/:manufacturerId`, Component: <SecuritySystems /> },
    { path: `${process.env.PUBLIC_URL}/security-systems/:securitySystemId/manufacturers/:manufacturerId/systems/:systemId`, Component: <SecuritySystems /> },
    { path: `${process.env.PUBLIC_URL}/security-systems/:securitySystemId/manufacturers/:manufacturerId/systems/:systemId/central-stations/:centralStationId`, Component: <SecuritySystems /> },

    // Prices
    { path: `${process.env.PUBLIC_URL}/prices`, Component: <DefaultPrices /> },
    { path: `${process.env.PUBLIC_URL}/prices/:priceId/:priceMethod?`, Component: <CreatePrice /> },

    // Users
    { path: `${process.env.PUBLIC_URL}/users/:confirmed/:userId?`, Component: <Users /> },

    // Schedule
    // { path: `${process.env.PUBLIC_URL}/schedule`, Component: <Schedule /> },
    { path: `${process.env.PUBLIC_URL}/schedule/users`, Component: <ScheduleUsers /> },
    { path: `${process.env.PUBLIC_URL}/schedule/users/:year/:weekNumber`, Component: <ScheduleUsers /> },
    { path: `${process.env.PUBLIC_URL}/schedule/exception/:exceptionId?`, Component: <Exception /> },
    { path: `${process.env.PUBLIC_URL}/schedule/events/:eventId/:eventMethod?`, Component: <CreateEvent /> },
    { path: `${process.env.PUBLIC_URL}/schedule/standby`, Component: <Standby /> }, // Dežurstvo
    { path: `${process.env.PUBLIC_URL}/schedule/standby/:standbyId/:standbyMethod?`, Component: <CreateStandby /> },
    { path: `${process.env.PUBLIC_URL}/schedule/task/:id/:method?`, Component: <CreateTask /> },

    // Archive
    { path: `${process.env.PUBLIC_URL}/archive`, Component: <Archive /> },

    // Profile
    { path: `${process.env.PUBLIC_URL}/profile`, Component: <UserProfile /> },

    // Mail
    { path: `${process.env.PUBLIC_URL}/mail`, Component: <Mail /> },
    { path: `${process.env.PUBLIC_URL}/mail/:id?`, Component: <MailSingle /> },

    // Statistic
    { path: `${process.env.PUBLIC_URL}/statistic`, Component: <Statistic /> },

    // Projects
    { path: `${process.env.PUBLIC_URL}/projects`, Component: <AllProjects /> },
    { path: `${process.env.PUBLIC_URL}/projects/:projectId/:projectMethod?`, Component: <Projects /> },
    { path: `${process.env.PUBLIC_URL}/projects/:projectId/logs/:logId/:logMethod?`, Component: <ProjectsLog /> },

    // Phonebook
    { path: `${process.env.PUBLIC_URL}/phonebook`, Component: <AllPhonebook /> },
    { path: `${process.env.PUBLIC_URL}/phonebook/:phonebookId/:phonebookMethod?`, Component: <Phonebook /> },
]
