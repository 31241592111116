import React, { Fragment, useState, useEffect } from 'react'
import {t} from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, Button, Collapse } from 'reactstrap'
import { useRecoilValue } from 'recoil'
import axios from 'axios'
import { Accordion } from 'react-bootstrap';

import env from '../../env/src_config'
import { headersState } from '../../recoil/recoil';
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb';
import Spinner from '../../components/spinner/Spinner'
import PaginationTop from '../../components/Pagination/PaginationTop'
import PaginationBottom from '../../components/Pagination/PaginationBottom'
import { axiosError } from '../../helpers/response'
import { isNull } from '../../izUtils'
import { transformer } from '../../helpers/fields'
import ExceptionList from '../../components/exception/lists/ExceptionList'
import AddUpdateException from '../../components/exception/AddUpdateException'
import FutureException from '../../components/exception/FutureException'
import Filter from '../../components/filters/Filter'

const Exception = () => {
    const Navigate = useNavigate();
    const { exceptionId } = useParams();

    const headers = useRecoilValue(headersState);
    const [isOpen, setIsOpen] = useState(null);
    const [users, setUsers] = useState(null);
    const [filters, setFilters] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const lsFilterName = 'AKODA.exceptionFilter';

    useEffect(() => {
        getUsers({
            confirmed: {value: true},
            type: {value: "technician"}
        });

        return () => {
            localStorage.removeItem(lsFilterName)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isNull(exceptionId) && exceptionId !== 'create') {
            setSelectedUsers([]);
        }

    }, [exceptionId])

    const getUsers = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }

        axios.post(env.api + '/api/user/list', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setUsers(response.data.data);
                if (!isNull(response.data.filters)) {
                    setFilters(transformer(response.data.filters));
                    localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
                }
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName);
        getUsers({
            confirmed: {value: true},
            type: {value: "technician"}
        });
    }

    if (isNull(users)) {
        return <Spinner />
    } else {
        const filterFields = ['type']
        return (
            <Fragment>
                <Breadcrumbs parent={t('breadcrumb.schedule')} title={t('breadcrumb.exception')} />
                <Container fluid={true}>
                    <Row>
                        <Col md="6">
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('schedule.exception.title')}</div>
                                    <div>
                                        <Accordion className='mb-3'>
                                            <div className="default-according style-1" id="clientfilter">
                                                <Button color="secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={() => setIsOpen(!isOpen)}>
                                                    {t('filters')}
                                                    <div className={'filter-icon' + (isOpen ? ' rotate-icon' : '')}>
                                                        <i className="fa fa-chevron-down"></i>
                                                    </div>
                                                </Button>
                                                <Collapse isOpen={isOpen}>
                                                    <div className='p-3'>
                                                        <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => getUsers(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </Accordion>
                                        <PaginationTop filters={filters} getData={clonedFilters => getUsers(clonedFilters, false)} />
                                        <ExceptionList data={users} selectedUsers={selectedUsers} setSelectedUsers={(data) => setSelectedUsers(data)} />
                                        <PaginationBottom filters={filters} getData={clonedFilters => getUsers(clonedFilters, false)} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {!isNull(exceptionId) &&
                            <Col md="6">
                                <AddUpdateException selectedUsers={selectedUsers} setSelectedUsers={(data) => setSelectedUsers(data)} />
                            </Col>
                        }
                    </Row>
                    <hr/>
                    <Row>
                        <Col xl="12" xxl="6">
                            <FutureException selectedUsers={selectedUsers} />
                        </Col>
                    </Row>
                    <hr/>
                    <div className='d-flex flex-wrap justify-content-between mb-4'>
                        <div>
                            <Button color="danger" className="mt-2 me-2" onClick={() => Navigate(-1)}>{t('back')}</Button>
                        </div>
                    </div>
                </Container>
            </Fragment>
        )
    }
}

export default Exception