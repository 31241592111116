import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {t} from 'i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Form, Row, Col, Button, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import axios from 'axios';

import { headersState, multiSelectLoading } from '../../../recoil/recoil';
import env from '../../../env/src_config';
import { isNull, getTimezoneOffset, displayDate } from '../../../izUtils';
import { axiosError, errorStatus } from '../../../helpers/response';
import { transformer, linker } from '../../../helpers/fields';
import Spinner from '../../spinner/Spinner';
import Loader from '../../spinner/Loader';

const Export = ({ setExportModalOpen }) => {
    const navigate = useNavigate()

    const setMultiLoading = useSetRecoilState(multiSelectLoading);
    const headers = useRecoilValue(headersState);
    const [inputs, setInputs] = useState(null);
    const [showErrors, setShowErrors] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);

    const lsFilterName = 'AKODA.scheduleFilter';

    useEffect(() => {
        let lsFilter = JSON.parse(localStorage.getItem(lsFilterName));
        let modalFilters = null;

        if (!isNull(lsFilter) && !isNull(lsFilter.technician) && !isNull(lsFilter.technician.value) && lsFilter.technician.value.length !== 0) {
            modalFilters = { technicians: { value: [lsFilter.technician.value] } };
        }

        updateData(false, modalFilters);

    }, []) // eslint-disable-line

    const updateData = (save, passedInputs) => {
        if (save) setExportLoading(true)
        let payload = { save };

        if (!isNull(passedInputs)) {
            payload.data = {};
            const keys = Object.keys(passedInputs);

            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }

            payload.data.time_adjustment = getTimezoneOffset();
        }

        let sendHeaders = { headers: {...headers} };
        if (save) {
            sendHeaders.responseType = 'blob';
        }

        axios.post(env.api + '/api/task/export', payload, sendHeaders).then(response => {
            if (save) {
                if (response.data.type.includes('spreadsheetml.sheet')) {
                    const href = window.URL.createObjectURL(response.data);
                    const a = document.createElement('a');
                    a.setAttribute('download', `${displayDate(payload.data.from, 'date')}-${displayDate(payload.data.to, 'date')}.xlsx`);
                    a.href = href;
                    a.click();
                    window.URL.revokeObjectURL(href); // Clean up the object URL
                } else {
                    errorStatus(response.data, t);
                    setShowErrors(true);
                }

                setExportLoading(false);
            }

            setMultiLoading(false) // Set loading for field MultiSelect to false
            if (!isNull(response.data.data)) {
                setInputs(transformer(response.data.data));
            }
        }).catch(error => {
            axiosError(error, navigate);
            setExportLoading(false)
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    const multiChange = (value, name, component) => {
        setMultiLoading(name + '-' + component?.id)
        let clonedInputs = {...inputs};
        if (clonedInputs[name].value.length === 0) {
            clonedInputs[name].value.push(value)
        } else {
            if (clonedInputs[name].value.indexOf(value) === -1) {
                clonedInputs[name].value.push(value);
            } else {
                const filteredValues = clonedInputs[name].value.filter(el => el !== value);
                clonedInputs[name].value = filteredValues;
            }
        }
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            component: {id: 'export'},
            multiHandler: (value, id, component) => multiChange(value, id, component),
            dateHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    if (isNull(inputs)) return <Spinner />
    return (
        <Fragment>
            {exportLoading && <Loader />}
            <ModalHeader toggle={setExportModalOpen}>{t('schedule.export.title')}</ModalHeader>
            <ModalBody>
                <Form className="theme-form">
                    <Row>
                        <Col sm="12">
                            { ['time_adjustment', 'from', 'to', 'technicians'].map(field => mapLinker(field)) }
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <div className='w-100 d-flex justify-content-between'>
                    <Button color="secondary" onClick={setExportModalOpen}>{t('close')}</Button>
                    <Button color="primary" onClick={() => updateData(true, inputs)}>{t('export')}</Button>
                </div>
            </ModalFooter>
        </Fragment>
    )
}

export default Export