import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'
import { Button, Form, Row, Col, Card, CardBody, CardFooter } from 'reactstrap'
import Swal from 'sweetalert2'

import env from '../../env/src_config'
import { headersState } from '../../recoil/recoil'
import { axiosError } from '../../helpers/response'
import { isNull } from '../../izUtils'
import { transformer, linker } from '../../helpers/fields'
import Spinner from '../spinner/Spinner'
import { errorStatus } from '../../helpers/response'
import { getSingleItem } from '../../helpers/getSingleItem'
import DisplayFields from '../../helpers/displayFields'

const AddUpdatePhonebook = () => {
    const Navigate = useNavigate();
    const { phonebookId, phonebookMethod } = useParams();

    const headers = useRecoilValue(headersState);
    const [inputs, setInputs] = useState(null);
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        if (!isNull(inputs)) setInputs(null)
        setShowErrors(false);

        if (phonebookId === 'create' || phonebookMethod === 'update') {
            updateData(false, null);
        } else {
            getSingleItem(headers, phonebookId, '/api/phonebook/', Navigate).then(data => {
                if (!isNull(data)) {
                    setInputs(data.fields);
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phonebookId])

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        // Create
        let url = env.api + '/api/phonebook/create'
        let method = 'post'
        // Update
        if (phonebookId !== 'create' && (!isNull(phonebookMethod) && phonebookMethod === 'update')) {
            url = env.api + '/api/phonebook/' + phonebookId
            method = 'patch'
        }

        axios[method](url, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        Navigate('/phonebook')
                    })
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        if (name === 'client') clonedInputs['facility'].value = "";
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field, place) => {
        const inputlinkerFields = {
            field,
            inputs: place,
            showErrors,
            component: {id: "createPhonebook"},
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
        }

        if ((phonebookId !== 'create' && isNull(phonebookMethod)) || phonebookMethod === 'get') {
            if (!isNull(place[field])) return <DisplayFields key={'display-'+place[field].name} data={place[field]} />
        } else {
            return linker(inputlinkerFields);
        }
    }

    return (
        <Card className="ribbon-wrapper">
            <CardBody>
                <div className="ribbon ribbon-clip ribbon-primary">{ (phonebookId === 'create') ? t('phonebook.create.title') : ( phonebookMethod === 'update' ? t('phonebook.update.title') : t('phonebook.get.title') ) }</div>
                {isNull(inputs) ?
                    <Spinner />
                    :
                    <Fragment>
                        <Form className="theme-form">
                            <Row>
                                <Col md="12">
                                    { ['client', 'facility', 'name', 'phone', 'email'].map(field => mapLinker(field, inputs)) }
                                </Col>
                            </Row>
                        </Form>
                    </Fragment>
                }
            </CardBody>
            {(phonebookId === 'create' || (!isNull(phonebookMethod) && (phonebookMethod === 'update'))) &&
                <CardFooter>
                    <div className='text-end'>
                        <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{t('phonebook.create.save')}</Button>
                    </div>
                </CardFooter>
            }
        </Card>
    )
}

export default AddUpdatePhonebook