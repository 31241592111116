import Swal from "sweetalert2";

import { isNull, displayDate } from "../../../izUtils";

export const handleEvent = (event, t, Navigate, token, deleteProject) => {
    const startDate = event.start
    const endDate = event.end
    let title = '';
    let html = '';
    let showCancelButton = false;
    let showDeleteButton = false;
    let confirmButtonText = t('close')

    if (event.type === 'task') {
        title = event.title + ' (' + event.serviceType.title + ')';
        html = `<div>
            <div style="font-weight: 500">${displayDate(startDate)} - ${displayDate(endDate)}</div>
            <div><span style="text-transform: uppercase;"><b>${t('client')}</b></span>: ${event.client.title}</div>
            <div><span style="text-transform: uppercase;"><b>${t('facility')}</b></span>: ${event.facility.title}</div>
            <div>
                <span style="text-transform: uppercase;"><b>${t('googleLocation')}</b></span>:
                <a href='https://www.google.com/maps/place/${event.facility.google_location}' target="_blank" rel="noreferrer">
                    ${event.facility.google_location}
                </a>
            </div>
            <div><span style="text-transform: uppercase;"><b>${t('security_systems')}</b></span>: ${(!isNull(event.security_systems) && event.security_systems.length !== 0) ? event.security_systems.map(ss => ss.title) : ""}</div>
            <div><span style="text-transform: uppercase;"><b>${t('table.technicians')}</b></span>: ${(!isNull(event.assignees) && event.assignees.length !== 0) ? event.assignees.map(tech => tech.name) : ""}</div>
            ${!isNull(event.clientContact?.phone) ?
                `<div>
                    <span style="text-transform: uppercase;"><b>${t('table.supervisors')}</b></span>:
                    <a href='tel:${event.clientContact.phone}'>
                        ${event.clientContact.name + ', ' + event.clientContact.phone}
                    </a>
                </div>`
                :
                ''
            }
            <br/>
        </div>`;

        if (["checkup",  "in_approval", "approved"].includes(event.status)) {
            html += `<div>${t('task.grayBG')}</div><br/>`;
        }

        if ( (token.parsedToken.type === 'admin') || (token.parsedToken.type === 'moderator') || ( (token.parsedToken.type === 'technician') && (event.userId === token.parsedToken.id) ) ) {
            html += `<div><a href='/task/${event.id}/${event.permissions['task.update'] ? 'update' : 'get'}' target="_blank" style="letter-spacing: 0px">${t('calendar.handleTaskLink')}</a></div>`
        }
    } else if (event.type === 'event') {
        if (token.parsedToken.type === 'admin' || token.parsedToken.type === 'moderator') {
            showDeleteButton = true;
            showCancelButton = true;
            confirmButtonText = t('edit')
        } else {
            showDeleteButton = false;
            showCancelButton = false;
        }

        title = event.title + ' (' + t(`calendar.${event.type}`) + ')';
        html = `<div>
            <div>${displayDate(startDate)} - ${displayDate(endDate)}</div>
            <div>${t('facility') +  ': ' + event.facility.title}</div>
            <div>${t('projectNumber') +  ': ' + event.project_number}</div>
            <br/>
        </div>`;

        if ( (token.parsedToken.type === 'admin') || (token.parsedToken.type === 'moderator') || ( (token.parsedToken.type === 'technician') && (event.userId === token.parsedToken.id) ) ) {
            html += `<div><a href='/schedule/events/${event.id}/get' target="_blank" style="letter-spacing: 0px">${t('calendar.handleEventLink')}</a></div>`
        }
    } else {
        title = event.title + ' (' + t(`calendar.${event.type}`) + ')';
        let exceptionName = t('calendar.' + event.exception);
        if (event.exception === 'other') exceptionName = event.exception_name;

        if (token.parsedToken.type === 'admin' || token.parsedToken.type === 'moderator') {
            confirmButtonText = t('edit')
            showCancelButton = true;
        }

        html = `<div>
            <div>${displayDate(startDate)} - ${displayDate(endDate)}</div>
            <div>${t('calendar.exception') +  ': ' + exceptionName}</div>
        </div>`;
    }

    Swal.fire({
        title,
        html,
        confirmButtonColor: 'var(--theme-default)',
        confirmButtonText,
        showCancelButton,
        cancelButtonText: t('cancel'),
        showDenyButton: showDeleteButton,
        denyButtonText: t('delete'),
    }).then(val => {
        if (event.type === 'event') {
            if (val.isConfirmed) {
                if (token.parsedToken.type === 'admin' || token.parsedToken.type === 'moderator') {
                    Navigate('/schedule/events/' + event.id + '/update')
                }
            } else if (val.isDenied) {
                deleteProject(event);
            }
        } else if (event.type === 'exception') {
            if (val.isConfirmed && (token.parsedToken.type === 'admin' || token.parsedToken.type === 'moderator')) {
                Navigate('/schedule/exception/' + event.id)
            }
        }
    })
}