import React, { useState, useEffect } from 'react'
import {t} from 'i18next'
import { FormGroup, Label, Row, Col, Button, Input } from 'reactstrap'

import { isNull } from '../../../izUtils'

const ProjectMaterials = ({ data, onChange, /*showErrors,*/ lsName }) => {
    const [materials, setMaterials] = useState(null)

    useEffect(() => {
        let values = null
        if (isNull(data.values) || data.values === '' || data.values.length === 0) {
            values = [];
        } else {
            values = data.values;
        }

        setMaterials(values);

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleMaterial = (e, type, i) => {
        let clonedMaterials = [...materials];
        clonedMaterials[i] = {
            ...clonedMaterials[i],
            [type]: e.target.value
        };
        setMaterials(clonedMaterials);
        saveToLocalStorage(clonedMaterials)
        if (!isNull(onChange)) onChange(clonedMaterials, data.name);
    }

    const addMaterial = () => {
        let clonedMaterials = [...materials];
        clonedMaterials.push({ name: "", quantity: "", mounted: "" });
        setMaterials(clonedMaterials)
        saveToLocalStorage(clonedMaterials)
    }

    const deleteMaterial = (index) => {
        let clonedMaterials = [...materials];
        clonedMaterials.splice(index, 1);
        setMaterials(clonedMaterials)
        saveToLocalStorage(clonedMaterials)
    }

    const saveToLocalStorage = (clonedMaterials) => {
        let lsData = JSON.parse(localStorage.getItem(lsName))
        lsData.project_materials.value = clonedMaterials
        localStorage.setItem(lsName, JSON.stringify(lsData))
    }

    if (data.display) {
        if (materials === null) return null
        return (
            <FormGroup style={{ position: 'relative' }} className={(data.name === 'project_log_materials') ? " red-background" : ""}>
                {data.name === 'project_materials' && <Label>{data.title}</Label>}
                {materials.map((material, index) => (
                    <div key={index}>
                        <Row className={"align-items-end"}>
                            <Col md="12" lg="7" className="mb-2">
                                {index === 0 && <Label>{t(`form.label.materials.name`)}</Label>}
                                <Input
                                    className={"form-control"}
                                    type="text"
                                    name={'material'}
                                    placeholder={t(`form.placeholder.materials.name`)}
                                    onChange={(e) => handleMaterial(e, 'name', index)}
                                    disabled={data.disabled}
                                    value={isNull(material.name) ? "" : material.name}
                                />
                            </Col>
                            <Col md="12" lg="2" className="mb-2">
                                {index === 0 && <Label>{t(`form.label.materials.quantity`)}</Label>}
                                <Input
                                    className="form-control"
                                    type="number"
                                    name={'quantity'}
                                    placeholder={t(`form.placeholder.materials.quantity`)}
                                    onChange={(e) => handleMaterial(e, 'quantity', index)}
                                    disabled={data.disabled}
                                    value={isNull(material.quantity) ? "" : material.quantity}
                                />
                            </Col>
                            {data.name === 'project_materials' &&
                                <>
                                    <Col md="12" lg="2" className="mb-2">
                                        {index === 0 && <Label>{t(`form.label.materials.mounted`)}</Label>}
                                        <Input
                                            className="form-control"
                                            type="number"
                                            name={'mounted'}
                                            placeholder={t(`form.placeholder.materials.mounted`)}
                                            onChange={(e) => handleMaterial(e, 'mounted', index)}
                                            disabled={true}
                                            value={isNull(material.mounted) ? "" : material.mounted}
                                        />
                                    </Col>
                                    <Col md="12" lg="1" className="mb-2 text-end">
                                        <Button color="secondary" disabled={data.disabled} className={"px-3"} onClick={() => deleteMaterial(index)}><i className="fa fa-trash"></i></Button>
                                    </Col>
                                </>
                            }
                        </Row>
                    </div>
                ))}
                {/* {showErrors && t('error')} */}
                {(data.name === 'project_materials') &&
                    <div>
                        <Button color="primary" onClick={addMaterial}>{t('projects.projectMaterials.add.material')}</Button>
                    </div>
                }
            </FormGroup>
        )
    } else {
        return null;
    }
}

export default ProjectMaterials