import React, { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import moment from 'moment'
import 'moment/locale/sl'

import CalnedarScrollItem from './CalnedarScrollItem';
import { calendarScrollWeeks, calendarScrollLoading } from '../../recoil/recoil';
import { getTimezoneOffset } from '../../izUtils';

const CalendarScroll = (props) => {

    const [csLoading, setcsLoading] = useRecoilState(calendarScrollLoading);
    const [weekStartDates, setWeekStartDates] = useRecoilState(calendarScrollWeeks);

    const firstTime = useRef(true);

    useEffect(() => {
        if (firstTime.current) {
            firstTime.current = false;
            return;
        } else {
            setWeekStartDates([]);
        }

    }, [props.calendarData.from]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        // Check if the state has been updated to an empty array
        if (weekStartDates.length === 0) {
            setcsLoading(true);
            addNextWeek();
        }

      }, [weekStartDates]); // eslint-disable-line react-hooks/exhaustive-deps


    // Detect scroll event to add more weeks
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, [csLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleScroll = () => {
        if ( ( (window.innerHeight + window.scrollY + 100) >= document.body.offsetHeight ) && !csLoading ) {
            // Load more weeks when the user is near the bottom of the page
            setcsLoading(true);
            addNextWeek();
        }
    };

    // Add the next week's start date to the state
    const addNextWeek = () => {
        setWeekStartDates((prevDates) => {
            const lastWeekStart = prevDates[prevDates.length - 1];
            const nextWeekStart = lastWeekStart ? moment(lastWeekStart).add(getTimezoneOffset(), 'hour').add(1, 'week').startOf('week').toDate() : moment(props.calendarData.from).add(getTimezoneOffset(), 'hour').add(1, 'week').startOf('week').toDate();

            return [...prevDates, nextWeekStart];
        });
    };

    return (
        <>
            {weekStartDates.map((weekStartDate, index) => (
                <CalnedarScrollItem key={index} index={index} weekStartDate={weekStartDate} {...props} />
            ))}
        </>
    )
}

export default CalendarScroll