import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilState } from 'recoil'
import { Button, Form, Card, CardBody, CardFooter } from 'reactstrap'
import Swal from 'sweetalert2'

import env from '../../../env/src_config'
import { headersState } from '../../../recoil/recoil'
import { axiosError, errorStatus } from '../../../helpers/response'
import { isNull } from '../../../izUtils'
import { transformer, linker } from '../../../helpers/fields'
import Spinner from '../../spinner/Spinner'

/* Modal for adding new security system (used in MultiSelect component) */
const AddSecuritySystem = ({handleModalButton, type, refreshParent}) => {
    const Navigate = useNavigate();
    const [headers] = useRecoilState(headersState);

    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        updateData(false, {});
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        let url = env.api + '/api/client/facility/system/create/'
        if (type === 'fromMultiSelect') {
            let lsData = JSON.parse(localStorage.getItem('AKODA.taskInputs'));
            url += lsData.facility.value;
        }

        axios.post(url, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    refreshParent()
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        handleModalButton('addSystem', null);
                    })
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
            dateHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    return (
        <>
            <Card className="ribbon-wrapper" style={{ marginBottom: '0px' }}>
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-primary">{t('addSystem')}</div>
                    {isNull(inputs) ?
                        <Spinner />
                        :
                        <Fragment>
                            <Form className="theme-form">
                                { ['security_system'/*, 'check_price', 'system_checks_number', 'system_warranty', 'system_warranty_date', 'check_notes'*/].map(field => mapLinker(field)) }
                            </Form>
                        </Fragment>
                    }
                </CardBody>
                <CardFooter>
                    <div className='d-flex justify-content-between'>
                        <Button color='secondary' className="mb-2 me-2" onClick={() => handleModalButton('addSystem', null)}>{t('close')}</Button>
                        <Button color="primary" className="mb-2 me-2" onClick={() => updateData(true, inputs)}>{t('add')}</Button>
                    </div>
                </CardFooter>
            </Card>
        </>
    )
}

export default AddSecuritySystem