import React, { useEffect, useState } from 'react'
import { Label, FormGroup } from 'reactstrap';

import { isNull } from '../../izUtils'
import { checkHistory } from '../../helpers/checkHistory'

const TimeInput = ({data, /*className,*/ onChange, showErrors, history}) => {
    const [time, setTime] = useState("");

    useEffect(() => {
        if (!isNull(data.value)) setTime(data.value)

    }, [data.value])

    const handleDate = (e) => {
        setTime(e.target.value)
    };

    const handleBlur = (e) => {
        onChange(e, data.name);
    }

    let hasHistory = false;
    if (!isNull(history)) {
        hasHistory = checkHistory(data, history)
    }

    return (
        <FormGroup className={hasHistory ? 'red-alert' : ''}>
            {!isNull(data.title) && <Label>{data.title + (data.mandatory ? " *" : "")}</Label>}
            <div className={'d-flex align-items-center' /*+ (isNull(className) ? '' : ' '+className)*/}>
                <input type="time" name={data.name} className="form-control digits form-control" disabled={data.disabled} value={time} onChange={handleDate} onBlur={handleBlur} />
            </div>
            {!isNull(showErrors) && showErrors && !isNull(data.errors) && data.errors.length !== 0 && <div style={{ color: "red", marginBottom: "10px" }}>{data.errors.map((error, index) => <div key={"error-"+index}>{error}</div>)}</div>}
        </FormGroup>
    )
}

export default TimeInput