import React from 'react'
import ProjectLog from './ProjectLog'

const ProjectLogs = ({ data/*, onChange, showErrors*/ }) => {
    return (
        <>
            {data.value.map((log, index) => {
                return (<ProjectLog key={index} index={index} log={log} />)}
            )}
        </>
    )
}

export default ProjectLogs