import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Calendar } from 'react-big-calendar'
import axios from 'axios';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import moment from 'moment'

import env from '../../env/src_config';
import { headersState, calendarScrollLoading } from '../../recoil/recoil';
import { sortEvent } from './helper/sortEvent';
import { axiosError } from '../../helpers/response';
import { getNavigateNumbers, isNull } from '../../izUtils';
import Spinner from '../spinner/Spinner';

const CalnedarScrollItem = ({index, weekStartDate, calendarData, localizer, view, views, messages, eventStyleGetter, lsFilterName}) => {
    const navigate = useNavigate()

    const headers = useRecoilValue(headersState);
    const setcsLoading = useSetRecoilState(calendarScrollLoading);
    const [events, setEvents] = useState(null)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const lsFilter = JSON.parse(localStorage.getItem(lsFilterName));
        let payload = { filters: {} };

        if (!isNull(lsFilter)) {
            const keys = Object.keys(lsFilter);
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(lsFilter[keys[i]].value) && lsFilter[keys[i]].value.length !== 0) {
                    payload.filters[keys[i]] = lsFilter[keys[i]].value;
                } else {
                    payload.filters[keys[i]] = "";
                }
            }
        }

        const navigateNumbers = getNavigateNumbers(moment.utc(calendarData.from).add(index + 1, 'week').toDate());
        let url = '/api/task/list/schedule/' + navigateNumbers.year + '/' + navigateNumbers.week;
        payload.filters.time_adjustment = (new Date().getTimezoneOffset() / 60)*-1;

        axios.post(env.api + url, payload, {headers}).then(response => {
            let sortedEvents = sortEvent(response.data);
            setEvents(sortedEvents);
            setLoading(false);
            setcsLoading(false);
        }).catch(error => {
            axiosError(error, navigate);
            setLoading(false);
            setcsLoading(false);
        });

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='calendar-no-header-btns'>
            {loading ?
                <Spinner />
                :
                <Calendar
                    localizer={localizer}
                    defaultDate={weekStartDate}
                    // onSelectEvent={handleSelectEvent}
                    events={events}
                    defaultView={view}
                    views={views}
                    step={30}
                    startAccessor="start"
                    endAccessor="end"
                    messages={messages}
                    eventPropGetter={eventStyleGetter}
                />
            }
        </div>
    )
}

export default CalnedarScrollItem