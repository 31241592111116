import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'
import { Button, Modal } from 'reactstrap'

import ProjectSystem from './ProjectSystem'
import ModalAddSystem from './modal/ModalAddSystem'

const ProjectSystems = ({ projectSystemsIds }) => {
    const { projectId, projectMethod } = useParams();

    const [projectSystems, setProjectSystems] = useState([]);
    const [modalAddSystem, setModalAddSystem] = useState(false);

    const modaltoggle = () => setModalAddSystem(val => !val)

    useEffect(() => {
        setProjectSystems(projectSystemsIds)

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const addProjectSystemId = (data) => {
        setProjectSystems(prev => ([...prev, data.id]))
    }

    // const removeSystemId = (index) => {
    //     console.log("REMOVE SYSTEM:",index);
    //     setProjectSystems((prev) => prev.filter(system => system !== index))
    // }

    return (
        <div className='mt-4'>
            {projectSystems.map((systemId, index) => (
                <div key={"project-system-"+index}>
                    {index === 0 && <hr/>}
                    <div className='mx-4'>
                        <ProjectSystem index={index} systemId={systemId} />
                    </div>
                    <hr/>
                </div>
            ))}
            {(projectId === 'create' || projectMethod === 'update') &&
                <div className='mb-2'>
                    <Button color="primary" onClick={modaltoggle}>{t('projects.projectMaterials.add.system')}</Button>
                </div>
            }

            <Modal isOpen={modalAddSystem} toggle={modaltoggle} centered size="xl">
                <ModalAddSystem modaltoggle={modaltoggle} addProjectSystemId={addProjectSystemId} /*removeSystemId={removeSystemId}*/ />
            </Modal>
        </div>
    )
}

export default ProjectSystems