import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilState } from 'recoil'
import Swal from 'sweetalert2'
import { Card, CardBody, CardFooter, Form, Button, Col, Row, Input, Label } from 'reactstrap'

import env from '../../env/src_config'
import { headersState } from '../../recoil/recoil';
import { isNull } from '../../izUtils'
import { axiosError } from '../../helpers/response'
import { transformer, linker } from '../../helpers/fields'
import { errorStatus } from '../../helpers/response'
import DisplayFields from '../../helpers/displayFields'
import { getSingleItem } from '../../helpers/getSingleItem'
import Spinner from '../spinner/Spinner'
import FileUpload from '../fileUpload/FileUpload'

const AddUpdateProjectLog = () => {
    const { projectId, logId, logMethod } = useParams();
    const Navigate = useNavigate();

    const [headers] = useRecoilState(headersState);
    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false)
    const [showDescription, setShowDescription] = useState(true)

    const lsProjectLogsInputs = 'AKODA.projectLogsInputs';
    const lsProjectLogsFiles = 'AKODA.projectLogsFiles';

    useEffect(() => {
        if (logId === 'create' || logMethod === 'update') {
            localStorage.removeItem(lsProjectLogsFiles)
            updateData(false, null);
        } else {
            getSingleItem(headers, logId, '/api/project/log/', Navigate).then(data => {
                if (!isNull(data)) {
                    setInputs(data.fields);
                }
            })
        }

        return () => {
            localStorage.removeItem(lsProjectLogsInputs)
            localStorage.removeItem(lsProjectLogsFiles)
        }
    }, [projectId]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            const toArray = ['technicians', 'project_materials', 'log_materials'];
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    if (toArray.indexOf(keys[i]) !== -1) {
                        payload.data[keys[i]] = []
                    } else {
                        payload.data[keys[i]] = "";
                    }
                }
            }

            // // Get data from localStorage
            let logFiles = JSON.parse(localStorage.getItem(lsProjectLogsFiles))
            if (!isNull(logFiles)) {
                if (isNull(payload.data)) payload.data = {};
                payload.data.files = logFiles.map(file => file.id);
            }
        }

        // let method = "patch";
        // if (logId === 'create') method = "post";
        axios.post(env.api + '/api/project/log/create/' + projectId, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => Navigate('/projects/'))
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data)) {
                const transformedInputs = transformer(response.data.data);
                setInputs(transformedInputs);
                localStorage.setItem(lsProjectLogsInputs, JSON.stringify(transformedInputs))
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;

        if (name === 'log_materials' || name === 'technicians') {
            setInputs(clonedInputs)
        } else {
            updateData(false, clonedInputs);
        }
    }

    const timeChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value.target.value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
            timeHandler: (value, id) => timeChange(value, id),
        }

        if (logMethod === 'get') {
            if (!isNull(inputs[field])) return <DisplayFields key={'display-'+inputs[field].name} data={inputs[field]} />
        } else {
            return linker(inputlinkerFields);
        }
    }

    let bottomFields = ['requires_review'];
    if (showDescription) bottomFields.unshift('work_description');
    return (
        <Card className="ribbon-wrapper">
            <CardBody>
                <div className="ribbon ribbon-clip ribbon-primary">{ (logId === 'create') ? t('projectsLog.create.title') : ( logMethod === 'update' ? t('projectsLog.update.title') : t('projectsLog.get.title') ) }</div>
                {isNull(inputs) ?
                    <Spinner />
                    :
                    <Fragment>
                        <Form className="theme-form">
                            <Row>
                                <Col sm="12" xl="6">
                                    {['client', 'facility', 'security_system'].map(field => mapLinker(field))}
                                    <Row>
                                        <Col md={6}>
                                            {['main_technician'].map(field => mapLinker(field))}
                                        </Col>
                                        <Col md={3}>
                                            {['worklog_from'].map(field => mapLinker(field))}
                                        </Col>
                                        <Col md={3}>
                                            {['worklog_to'].map(field => mapLinker(field))}
                                        </Col>
                                    </Row>
                                    {['technicians', 'log_materials'].map(field => mapLinker(field))}
                                    <div className="custom-control custom-checkbox form-check">
                                        <div className="form-checkbox-group mb-1">
                                            <Input type="checkbox" id="show_description" name="show_description" checked={showDescription} onChange={() => setShowDescription(!showDescription)} />
                                            <Label className="checkmark" htmlFor={"show_description"}></Label>
                                            <Label className="custom-control-label" htmlFor={"show_description"} style={{ cursor: 'pointer' }} >{t('projects.projectMaterials.show.description')}</Label>
                                        </div>
                                    </div>
                                    {bottomFields.map(field => mapLinker(field))}
                                </Col>
                                <Col sm="12" xl="6">
                                    <FileUpload data={inputs.files} apiUrl="/api/project/log/file/" saveToLS={lsProjectLogsFiles} inputType="createProjectsLog" showErrors={showErrors} />
                                </Col>
                            </Row>
                        </Form>
                    </Fragment>
                }
            </CardBody>
            {(logId === 'create' || (logMethod === 'update')) &&
                <CardFooter>
                    <div className='text-end'>
                        <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{t('save')}</Button>
                    </div>
                </CardFooter>
            }
        </Card>
    )
}

export default AddUpdateProjectLog