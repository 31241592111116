import React from 'react'
import { Link } from 'react-router-dom'
import {t} from 'i18next'
import { ListGroup, ListGroupItem } from 'reactstrap'

import { isNull, displayDate } from '../../izUtils'

const RelatedTasks = ({relatedTasks}) => {
    if (!isNull(relatedTasks) && relatedTasks.length !== 0) {
        return (
            <div>
                <h6>{t('error')}</h6>
                <ListGroup className="list-group-flush">
                    {relatedTasks.map(relatedTask => (
                        <ListGroupItem key={"related-task-"+relatedTask.technician.id}>
                            <div>{t('schedule.exception.create.others', { technician: relatedTask.technician.name })}</div>
                            {relatedTask.tasks.map(task => (
                                <div key={"task-"+task.id}>
                                    <Link to={"/task/" + task.id}>{task.client.client}</Link>
                                </div>
                            ))}
                            {relatedTask.exception.map(exception => (
                                <div key={"exception-"+exception.id}>
                                    <Link to={"/schedule/exception/" + exception.id}>{t(`schedule.exception.create.${exception.exception}`)}: {displayDate(exception.from, 'date')} - {displayDate(exception.to, 'date')}</Link>
                                </div>
                            ))}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
        )
    }
}

export default RelatedTasks