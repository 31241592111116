import React, { Fragment } from 'react'
import {t} from 'i18next'
import { useNavigate } from 'react-router-dom'
import { Container, Button, Row, Col } from 'reactstrap'

import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb'
import AddUpdateProjectLog from '../../components/projects/AddUpdateProjectLog'

const ProjectsLog = () => {
    const Navigate = useNavigate();

    return (
        <Fragment>
            <Breadcrumbs parent={t('breadcrumb.projectsLog')} title={t('breadcrumb.projectsLog')} />
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <AddUpdateProjectLog />
                    </Col>
                </Row>
                <div className='d-flex flex-wrap justify-content-between mb-4'>
                    <Button color="danger" className="mt-2 me-2" onClick={() => Navigate(-1)}>{t('back')}</Button>
                </div>
            </Container>
        </Fragment>
    )
}

export default ProjectsLog