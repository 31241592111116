import React, { useState, useEffect } from 'react'
import {t} from 'i18next'
import { Form, FormGroup, Label, Row, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { isNull } from '../../../izUtils'

const LogMaterials = ({ data, onChange/*, showErrors*/ }) => {

    const [materials, setMaterials] = useState(null)
    const [showMaterials, setShowMaterials] = useState(true)

    const [modalAddMaterial, setModalAddMaterial] = useState(false);
    const modaltoggle = () => setModalAddMaterial(val => !val)

    useEffect(() => {
        let values = null
        if (isNull(data.values) || data.values === '' || data.values.length === 0) {
            values = [];
        } else {
            values = data.values;
        }

        setMaterials(values);

    }, [/*data.value*/]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleMaterial = (e, type, i) => {
        let clonedMaterials = [...materials];
        clonedMaterials[i] = {
            ...clonedMaterials[i],
            [type]: e.target.value
        };
        setMaterials(clonedMaterials);
        onChange(clonedMaterials, data.name)
    }

    const addMaterial = () => {
        let clonedMaterials = [...materials];
        clonedMaterials.push({ name: "", quantity: "" });
        setMaterials(clonedMaterials)
    }

    const deleteMaterial = (index) => {
        let clonedMaterials = [...materials];
        clonedMaterials.splice(index, 1);
        setMaterials(clonedMaterials)
    }

    const addProjectMaterial = () => {
        setModalAddMaterial(true);
    }

    const lsData = JSON.parse(localStorage.getItem('AKODA.projectLogsInputs'));
    const options = (!isNull(lsData) && !isNull(lsData.project_materials)) ? lsData.project_materials.values : [];

    const handleSelect = (e) => {
        const selectedOption = options.find(option => option.id === e.target.value)
        let clonedMaterials = [...materials];
        clonedMaterials.push({ project_material_id: e.target.value, name: selectedOption.name, quantity: selectedOption.quantity });
        setMaterials(clonedMaterials)
        onChange(clonedMaterials, data.name)
        setModalAddMaterial(false);
    }

    if (data.display) {
        if (materials === null) return null
        return (
            <>
                <div className="custom-control custom-checkbox form-check">
                    <div className="form-checkbox-group mb-1">
                        <Input type="checkbox" id="show_materials" name="show_materials" checked={showMaterials} onChange={() => setShowMaterials(!showMaterials)} />
                        <Label className="checkmark" htmlFor={"show_materials"}></Label>
                        <Label className="custom-control-label" htmlFor={"show_materials"} style={{ cursor: 'pointer' }} >{t('projects.projectMaterials.show.materials')}</Label>
                    </div>
                </div>
                {showMaterials &&
                    <>
                        <FormGroup style={{ position: 'relative' }}>
                            <Label>{data.title}</Label>
                            {materials.map((material, index) => (
                                <div key={index}>
                                    <Row className={"align-items-end"}>
                                        <Col sm="12" md="8" className="mb-2">
                                            {index === 0 && <Label>{t(`form.label.materials.name`)}</Label>}
                                            <Input
                                                className={"form-control"}
                                                type="text"
                                                name={'material'}
                                                placeholder={t(`form.placeholder.materials.name`)}
                                                onChange={(e) => handleMaterial(e, 'name', index)}
                                                disabled={data.disabled}
                                                value={isNull(material.name) ? "" : material.name}
                                            />
                                        </Col>
                                        <Col sm="12" md="3" className="mb-2">
                                            {index === 0 && <Label>{t(`form.label.materials.quantity`)}</Label>}
                                            <Input
                                                className="form-control"
                                                type="number"
                                                name={'quantity'}
                                                placeholder={t(`form.placeholder.materials.quantity`)}
                                                onChange={(e) => handleMaterial(e, 'quantity', index)}
                                                disabled={data.disabled}
                                                value={isNull(material.quantity) ? "" : material.quantity}
                                            />
                                        </Col>
                                        <Col sm="12" md="1" className="mb-2 text-end">
                                            <Button color="secondary" disabled={data.disabled} className={"px-3"} onClick={() => deleteMaterial(index)}><i className="fa fa-trash"></i></Button>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            {/* {showErrors && t('error')} */}
                            <div>
                                <Button color="primary" className='me-2 mb-2' onClick={addProjectMaterial}>{t('projects.projectMaterials.add.material')}</Button>
                                <Button color="primary" className='me-2 mb-2' onClick={addMaterial}>{t('projects.projectMaterials.add.manual')}</Button>
                            </div>
                        </FormGroup>
                        <Modal isOpen={modalAddMaterial} toggle={modaltoggle} centered size="xl">
                            <ModalHeader toggle={modaltoggle}>
                                {t('projects.projectMaterials.add.material')}
                            </ModalHeader>
                            <ModalBody>
                                <Form className="theme-form">
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <Input
                                                    className={"form-control"}
                                                    type="select"
                                                    onChange={handleSelect}
                                                >
                                                    <option key="default-option" /*value={""}*/ style={{ fontWeight: '600' }}>{t('defaultSelect')}</option>
                                                    {!isNull(options) &&
                                                        options.map((option, index) => (
                                                            <option key={index} value={option.id}>{option.name}</option>
                                                        ))
                                                    }
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <div className='w-100 d-flex justify-content-between'>
                                    {/* <Button color="primary" className="me-2" onClick={() => updateData(true, inputs)}>{t('save')}</Button>  */}
                                    <Button color="danger" className='me-2' onClick={modaltoggle}>{t('close')}</Button>
                                </div>
                            </ModalFooter>
                        </Modal>
                    </>
                }
            </>
        )
    } else {
        return null;
    }
}

export default LogMaterials