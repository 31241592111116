import React from 'react'
import {t} from 'i18next'
import moment from 'moment'

import { displayDate } from '../izUtils';

const DisplayHistory = ({history}) => {
    return (
        <div className="field-history">
            {t('edited')}: {history?.changed_by.name} - {displayDate(moment.utc(history?.changed_at))}
        </div>
    )
}

export default DisplayHistory