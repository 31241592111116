import React, {useState, useEffect} from 'react';
import { FormGroup, Label, Input } from 'reactstrap'

import { isNull } from '../../izUtils';
import DisplayHistory from '../../helpers/DisplayHistory';
import { checkHistory } from '../../helpers/checkHistory';

const TextArea = ({data, onChange, onBlur, showErrors, history}) => {
    const { type, name, display, errors, title, disabled, mandatory} = data;

    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(data.value === null ? '' : data.value);
    }, [data.value]);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    if (display) {
        let hasHistory = false;
        if (!isNull(history)) {
            hasHistory = checkHistory(data, history)
        }

        return (
            <FormGroup className={(data.name === 'reject_message' || hasHistory ) ? 'red-alert' : ''}>
                <Label>{title + (mandatory ? " *" : "")}</Label>
                <Input
                    type={type}
                    className={"form-control"}
                    rows="4"
                    id={name}
                    value={value}
                    disabled={disabled}
                    onChange={handleChange}
                    onBlur={() => isNull(onBlur) ? {} : onBlur(value, name)}
                />
                {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && <div style={{ color: "red", marginBottom: "10px" }}>{errors.map((error, index) => <div key={"error-"+index}>{error}</div>)}</div>}
                {hasHistory && <DisplayHistory history={history} />}
            </FormGroup>
        )
    } else {
        return null;
    }
}

export default TextArea