import React, { Fragment, useEffect, useState, useRef } from 'react'
import {t} from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil'
import axios from 'axios';
import { Accordion, ModalFooter } from 'react-bootstrap';
import { Form, FormGroup, Label, Container, Card, CardBody, Button, Row, Col, Collapse, Modal, ModalHeader, ModalBody } from 'reactstrap'
import Swal from 'sweetalert2';

import env from '../../env/src_config';
import { headersState, tokenState } from '../../recoil/recoil';
import { isNull } from '../../izUtils';
import Spinner from '../../components/spinner/Spinner';
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb';
import { transformer, linker } from '../../helpers/fields';
import { axiosError, errorStatus } from '../../helpers/response';
import PaginationTop from '../../components/Pagination/PaginationTop';
import PaginationBottom from '../../components/Pagination/PaginationBottom';
import AllFacilityList from '../../components/facilities/AllFacilityList';
import Filter from '../../components/filters/Filter';
import Loader from '../../components/spinner/Loader';

const AllFacilities = () => {
    const navigate = useNavigate();
    const headers = useRecoilValue(headersState);
    const token = useRecoilValue(tokenState);

    const [facilities, setFacilities] = useState(null)
    const [tableLoading, setTableLoading] = useState(false)
    const [filters, setFilters] = useState(null)
    const [isFilterOpen, setIsFilterOpen] = useState(true);

    const [modalOpen, setModalOpen] = useState(null)
    const [securitySystemComponents, setSecuritySystemComponents] = useState(null)
    const [contacts, setContacts] = useState(null)
    const [inputs, setInputs] = useState(null)

    const selectedRow = useRef(null);
    const lsFilterName = 'AKODA.facilitiesAllFilter';

    useEffect(() => {
        const lsFilter = JSON.parse(localStorage.getItem(lsFilterName));
        getFacilities(isNull(lsFilter) ? {} : lsFilter);

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getFacilities = (passedFilters) => {
        setTableLoading(true);

        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }

        axios.post(env.api + '/api/client/facility/all', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setFacilities(response.data.data);
                if (!isNull(response.data.filters)) {
                    setFilters(transformer(response.data.filters));
                    localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
                }
            }
            setTableLoading(false);
        }).catch(error => {
            setTableLoading(false);
            axiosError(error, navigate);
        });
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName);
        getFacilities({});
    }

    // Modal Systems
    const showSystems = (system) => {
        setModalOpen('modalSystem')
        axios.post(env.api + '/api/client/facility/system/components/list/' + system.id, {}, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setSecuritySystemComponents(response.data.data);
            }
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const closeSystems = () => {
        setSecuritySystemComponents(null);
        setModalOpen(null);
    }

    // Modal Contacts
    const showContact = (data) => {
        setModalOpen('modalContacts')
        let mainSup = data.supervisors.find(sup => sup.main_supervisor);
        setContacts(mainSup);
    }

    const closeContacts = () => {
        setModalOpen(null);
        setContacts(null);
    }

    const showInputs = (data) => {
        selectedRow.current = data;
        setModalOpen('modalInputs');
        updateData(false, null);
    }

    const closeInputs = () => {
        setModalOpen(null);
        setInputs(null);
    }

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        axios.post(env.api + '/api/client/facility/report/' + selectedRow.current.id, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        closeInputs();
                    })
                } else {
                    // setShowErrors(true)
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) {
                setInputs(transformer(response.data.data));
            }
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            // showErrors,
            textHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    if (isNull(facilities)) {
        return <Spinner />
    } else {
        const filterFields = ['client', 'name', 'location', 'security_system', 'manufacturer', 'system', 'central']
        return (
            <Fragment>
                <Breadcrumbs title={t('breadcrumb.allFacilities')} />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('facilities.all.title')}</div>
                                    <div>
                                        <Accordion className='mb-3'>
                                            <div className="default-according style-1" id="facilityfilter">
                                                <Button color="secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={() => setIsFilterOpen(!isFilterOpen)}>
                                                    {t('filters')}
                                                    <div className={'filter-icon' + (isFilterOpen ? ' rotate-icon' : '')}>
                                                        <i className="fa fa-chevron-down"></i>
                                                    </div>
                                                </Button>
                                                <Collapse isOpen={isFilterOpen}>
                                                    <div className='p-3'>
                                                        <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => getFacilities(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </Accordion>
                                        <div style={{ position: 'relative' }}>
                                            {tableLoading && <Loader />}
                                            <PaginationTop filters={filters} getData={clonedFilters => getFacilities(clonedFilters)} />
                                            <AllFacilityList data={facilities} headers={headers} token={token} showSystems={showSystems} showContact={showContact} showInputs={showInputs} />
                                            <PaginationBottom filters={filters} getData={clonedFilters => getFacilities(clonedFilters)} />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <hr/>
                    <div className='d-flex flex-wrap justify-content-between mb-4'>
                        <Button color="danger" className="mt-2 me-2" onClick={() => navigate(-1)}>{t('back')}</Button>
                    </div>
                </Container>

                {/* MODALS */}
                <Modal isOpen={modalOpen === 'modalSystem'} toggle={closeSystems} centered size="xl">
                    <ModalHeader toggle={closeSystems}>
                        {t('facilities.securitySystems.title')}
                    </ModalHeader>
                    <ModalBody>
                        {isNull(securitySystemComponents) ?
                            <Spinner />
                            :
                            (securitySystemComponents.length === 0 ?
                                t('facilities.securitySystems.noData')
                                :
                                <Col sm="12">
                                    <Row className='d-none d-lg-flex m-0 py-1'>
                                        <Col lg="3" className="fw-bold">{t('table.manufacturer')}</Col>
                                        <Col lg="3" className="fw-bold">{t('table.system')}</Col>
                                        <Col lg="2" className="fw-bold">{t('table.central_station')}</Col>
                                        <Col lg="2" className="fw-bold">{t('table.component_notes')}</Col>
                                        <Col lg="2" className="fw-bold">{t('table.component_number')}</Col>
                                    </Row>
                                    <div className='contract-system-list'>
                                        {securitySystemComponents.map((ssc, i) => {
                                            return <Row key={"systemComponent-"+i} className="m-0 py-1">
                                                <Col lg="3"><span className="d-inline d-lg-none fw-bold">{t('table.manufacturer')}: </span>{ssc?.manufacturer[0]?.title}</Col>
                                                <Col lg="3"><span className="d-inline d-lg-none fw-bold">{t('table.system')}: </span>{ssc?.system[0]?.title}</Col>
                                                <Col lg="2"><span className="d-inline d-lg-none fw-bold">{t('table.central_station')}: </span>{ssc?.central_station[0]?.title}</Col>
                                                <Col lg="2"><span className="d-inline d-lg-none fw-bold">{t('table.component_notes')}: </span>{ssc?.component_notes}</Col>
                                                <Col lg="2"><span className="d-inline d-lg-none fw-bold">{t('table.component_number')}: </span>{ssc?.component_number}</Col>
                                            </Row>
                                        })}
                                    </div>
                                </Col>
                            )
                        }
                    </ModalBody>
                </Modal>
                <Modal isOpen={modalOpen === 'modalContacts'} toggle={closeContacts} centered size="lg">
                    <ModalHeader toggle={closeContacts}>
                        {t('facilities.contacts.title')}
                    </ModalHeader>
                    <ModalBody>
                        {isNull(contacts) ?
                            <Spinner />
                            :
                            <>
                                <FormGroup>
                                    <Label>{t('facilities.contacts.name')}</Label>
                                    <div>{contacts.email}</div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>{t('facilities.contacts.phone')}</Label>
                                    <div>{contacts.phone}</div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>{t('facilities.contacts.email')}</Label>
                                    <div>{contacts.name}</div>
                                </FormGroup>
                            </>
                        }
                    </ModalBody>
                </Modal>
                <Modal isOpen={modalOpen === 'modalInputs'} toggle={closeInputs} centered size="xl">
                    <ModalHeader toggle={closeInputs}>
                        {t('facilities.inputs.title')}
                    </ModalHeader>
                    <ModalBody>
                        {isNull(inputs) ?
                            <Spinner />
                            :
                            <Form className="theme-form">
                                <Row>
                                    <Col lg="6">
                                        {['supervisor_name', 'supervisor_phone', 'supervisor_email', 'facility_changes'].map(field => mapLinker(field)) }
                                    </Col>
                                </Row>
                            </Form>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{t('save')}</Button>
                        </div>
                    </ModalFooter>
                </Modal>


            </Fragment>
        )
    }
}

export default AllFacilities