import React, { Fragment, useEffect, useState } from 'react'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Row, Col } from 'reactstrap'
import axios from 'axios';
import Swal from 'sweetalert2';

import env from '../../../env/src_config';
import { headersState } from '../../../recoil/recoil';
import { isNull } from '../../../izUtils';
import { linker, transformer } from '../../../helpers/fields';
import DisplayFields from '../../../helpers/displayFields';
import { getSingleItem } from '../../../helpers/getSingleItem';
import { axiosError, errorStatus } from '../../../helpers/response';
import Spinner from '../../spinner/Spinner';
import FileUpload from '../../fileUpload/FileUpload';

const InventoryReceiptItem = ({inventoryReceipt, index, permissions, DeleteInventoryReceiptId}) => {
    const navigate = useNavigate();

    const headers = useRecoilValue(headersState);
    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false);

    const lsInventoryReceiptInputs = 'AKODA.inventoryReceiptInputs-'+index;
    const lsInventoryReceiptFiles = 'AKODA.inventoryReceiptFiles-'+index;

    useEffect(() => {
        if (!isNull(permissions) && permissions['inventory_receipt.update']) {
            localStorage.removeItem(lsInventoryReceiptFiles)
            updateData(false, null);
        } else {
            getSingleItem(headers, inventoryReceipt.id, '/api/inventory_receipt/', navigate).then(data => {
                if (!isNull(data)) {
                    setInputs(data.fields);
                    localStorage.setItem(lsInventoryReceiptInputs, JSON.stringify(data.fields));
                }
            })
        }

        return () => {
            localStorage.removeItem(lsInventoryReceiptInputs)
            localStorage.removeItem(lsInventoryReceiptFiles)
        }
    }, [inventoryReceipt.id]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs) => {
        let payload = { save };

        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }

            // Get data from localStorage
            let inventoryReceiptFiles = JSON.parse(localStorage.getItem(lsInventoryReceiptFiles))
            if (!isNull(inventoryReceiptFiles)) {
                if (isNull(payload.data)) payload.data = {};
                payload.data.files = inventoryReceiptFiles.map(file => file.id);
            }
        }

        axios.patch(env.api + '/api/inventory_receipt/' + inventoryReceipt.id, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.valid) && response.data.valid) {
                    Swal.fire({
                        title:  t('saved'),
                        text:  response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        console.log("Ka pa zdej?")
                        // toggle(index);
                    })
                } else {
                    errorStatus(response.data, t);
                    setShowErrors(true);
                }
            }

            if (!isNull(response.data.data)) {
                let transformedResponse = transformer(response.data.data)
                setInputs(transformedResponse)
                localStorage.setItem(lsInventoryReceiptInputs, JSON.stringify(transformedResponse));
            }
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
        }

        if (isNull(permissions) || !permissions['inventory_receipt.update']) {
            if (!isNull(inputs[field])) return <DisplayFields key={'display-'+inputs[field].name} data={inputs[field]} />
        } else {
            return linker(inputlinkerFields);
        }
    }

    const onDelete = () => {
        Swal.fire({
            text: t('inventoryReceipt.delete.text'),
            icon: 'question',
            confirmButtonColor: 'var(--theme-default)',
            confirmButtonText: t('ok'),
            showCancelButton: true,
            cancelButtonText: t('cancel'),
        }).then(val => {
            if (val.isConfirmed) {
                axios.delete(env.api + '/api/inventory_receipt/' + inventoryReceipt.id, {headers}).then(response => {
                    if (response.data.state === 'success') {
                        DeleteInventoryReceiptId(inventoryReceipt);
                        Swal.fire({
                            text: response.data.message,
                            icon: 'success',
                            confirmButtonColor: 'var(--theme-default)',
                            confirmButtonText: t('ok'),
                        })
                    } else {
                        errorStatus(response.data, t);
                    }
                }).catch(error => {
                    axiosError(error, navigate);
                });
            }
        })
    }

    return (
        <div>
            {isNull(inputs) ?
                <Spinner />
                :
                <Fragment>
                    {index !== 0 && <hr/>}
                    <Row>
                        <Col md="12">
                            <Row>
                                <Col md={(!isNull(permissions) && permissions['inventory_receipt.update']) ? "5" : "6"}>
                                    { ['number'].map(field => mapLinker(field)) }
                                </Col>
                                <Col md={(!isNull(permissions) && permissions['inventory_receipt.update']) ? "6" : "6"}>
                                    <FileUpload data={inputs.files} saveToLS={lsInventoryReceiptFiles} apiUrl="/api/inventory_receipt/file/" /*noDelete={true}*/ inputType="singleInventoryReceipt" />
                                </Col>
                                {(!isNull(permissions) && permissions['inventory_receipt.update']) &&
                                    <Col md="1">
                                        <div className='d-flex justify-content-center mt-4'>
                                            <i className= "icon-trash bigger-icon" onClick={() => onDelete()}></i>
                                        </div>
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                </Fragment>
            }
        </div>
    )
}

export default InventoryReceiptItem