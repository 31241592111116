import React, {useEffect, useState, useRef, Fragment} from 'react'
import {t} from 'i18next'
import SignatureCanvas from 'react-signature-canvas'
import { Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap'

import { isNull } from '../../../izUtils'
import DisplayHistory from '../../../helpers/DisplayHistory'
import { checkHistory } from '../../../helpers/checkHistory'

const MoadlSignature = ({name, onChange, modaltoggle}) => {
    const [canvasProps, setCanvasProps] = useState({height: 150, width: 600, className: 'sigCanvas'})
    const sigParent = useRef()
    const sigCanvas = useRef()

    useEffect(() => {
        if (!isNull(sigCanvas.current)) {
            sigCanvas.current.clear();
        }

        function updateCanvasWidth() {
            if (sigParent.current) {
                let clonedCanvasProps = {...canvasProps}
                clonedCanvasProps.width = sigParent.current.offsetWidth
                setCanvasProps(clonedCanvasProps)
            }
        }

        updateCanvasWidth()
        window.addEventListener("resize", updateCanvasWidth);

        return () => window.removeEventListener("resize", updateCanvasWidth);
    }, [sigCanvas.current]) // eslint-disable-line react-hooks/exhaustive-deps

    const onClear = () => {
        onChange(null, name)
        sigCanvas.current.clear();
    }

    const onSign = () => {
        // onChange(sigCanvas.current.getTrimmedCanvas().toDataURL('image/svg+xml'), name)
        onChange(sigCanvas.current.toDataURL(), name)
        modaltoggle()
    }

    return (
        <Fragment>
            <ModalHeader toggle={modaltoggle}>
                {t('service.signModal.title')}
            </ModalHeader>
            <ModalBody>
                <div ref={sigParent}>
                    <SignatureCanvas
                        ref={sigCanvas}
                        backgroundColor={'#fff'}
                        canvasProps={canvasProps}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <div className='w-100 d-flex justify-content-between'>
                    <Button color="secondary" className='me-2' onClick={onClear}>{t('service.signModal.clear')}</Button>
                    <Button color="primary" className='me-2' onClick={onSign}>{t('service.signModal.sign')}</Button>
                </div>
            </ModalFooter>
        </Fragment>
    )
}


const Signature = ({data, onChange, showErrors, history}) => {
    const { name, display, errors, title, disabled, mandatory } = data;

    const [modalOpen, setModalOpen] = useState(false)
    const [signatureValue, setSignatureValue] = useState(null)

    useEffect(() => {
        setSignatureValue(data.value)

    }, [data.value])

    const modaltoggle = () => setModalOpen(val => !val)

    if (display) {
        let hasHistory = false;
        if (!isNull(history)) {
            hasHistory = checkHistory(data, history)
        }

        return (
            <FormGroup className={hasHistory ? 'red-alert' : ''}>
                <Label>{title + (mandatory ? " *" : "")}</Label>
                <div className='sigCanvas-container'>
                    {(!isNull(signatureValue) && signatureValue !== '' ) && <img src={signatureValue} className='sigCanvas' alt="signature" />}
                </div>
                {(!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0) && <div style={{ color: "red", marginBottom: "10px" }}>{errors.map((error, index) => <div key={"error-"+index}>{error}</div>)}</div>}
                {hasHistory && <DisplayHistory history={history} />}
                {!disabled &&
                    <div>
                       <Button color="primary" className="mt-2" onClick={modaltoggle}>{t('service.signModal.editSign')}</Button>
                    </div>
                }
                <Modal isOpen={modalOpen} toggle={modaltoggle} centered size="xl">
                    <MoadlSignature name={name} onChange={onChange} modaltoggle={modaltoggle} />
                </Modal>
            </FormGroup>
        )
    } else {
        return null
    }
}

export default Signature