import React, { Fragment } from 'react'
import {t} from 'i18next'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col, Button } from 'reactstrap'

import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb'
import AddUpdatePhonebook from '../../components/phonebook/AddUpdatePhonebook'

const Phonebook = () => {
    const Navigate = useNavigate();

    return (
        <Fragment>
            <Breadcrumbs parent={t('breadcrumb.phonebook')} title={t('breadcrumb.phonebook')} />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <AddUpdatePhonebook />
                    </Col>
                </Row>
                <div className='d-flex flex-wrap justify-content-between mb-4'>
                    <Button color="danger" className="mt-2 me-2" onClick={() => Navigate('/phonebook')}>{t('back')}</Button>
                </div>
            </Container>
        </Fragment>
    )
}

export default Phonebook