import { areArraysEqual } from "../izUtils";

export function checkHistory(data, history) {
    let hasHistory = false;

    switch (data.type) {
        case 'multiselect':
            hasHistory = !areArraysEqual(data.value, history.field.value);
            break;
        case 'technicians':
            history.field.values.forEach(hVal => {
                delete hVal.title;
                delete hVal.demanding_work;
            });
            hasHistory = !areArraysEqual(data.value, history.field.values);
            break;
        case 'material':
            hasHistory = !areArraysEqual(data.value, history.field.value);
            break;
        default:
            hasHistory = data.value !== history.field.value;
            break;
    }

    return hasHistory;
}