import React, { Fragment } from 'react';
import {t} from 'i18next'
import { useRecoilValue } from 'recoil';
import { Form, Row, Col, Button, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import axios from 'axios';
import {useDropzone} from 'react-dropzone'
import Swal from 'sweetalert2';

import { headersState } from '../../../recoil/recoil';
import env from '../../../env/src_config';
import { isNull } from '../../../izUtils';
import { axiosError, errorStatus } from '../../../helpers/response';

const ImportModal = ({ setImportModalOpen, getList }) => {
    const headers = useRecoilValue(headersState);

    const uploadFile = (file) => {
        const formData = new FormData();
        formData.append('file', file);

        axios.post(env.api + '/api/standby/import', formData, {headers}).then(response => {
            if (!isNull(response.data.state) && response.data.state === 'success') {
                getList();
                Swal.fire({
                    text: response.data.message,
                    icon: 'success',
                    confirmButtonColor: 'var(--theme-default)',
                    confirmButtonText: t('ok'),
                }).then(() => {
                    setImportModalOpen();
                })
            } else {
                errorStatus(response.data, t);
            }
        }).catch(error => {
            axiosError(error);
        });
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            acceptedFiles.forEach(file => {
                uploadFile(file)
            })
        }
    })

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: 'var(--theme-default)',
        borderStyle: 'dashed',
        backgroundColor: '#00aeef20',
        color: '#000',
        outline: 'none',
        transition: 'border .24s ease-in-out'
      };

    const style = {...baseStyle}

    return (
        <Fragment>
            <ModalHeader toggle={setImportModalOpen}>{t('standby.import.title')}</ModalHeader>
            <ModalBody>
                <Form className="theme-form">
                    <Row>
                        <Col sm="12">
                            <div className='file-content'>
                                <div className='file-manager '>
                                    <div className='files'>
                                        <Row>
                                            <Col sm="12">
                                                <div className='mb-2'>
                                                    <div {...getRootProps({style})}>
                                                        <input {...getInputProps()} />
                                                        <p className='text-center'>{t('dragndrop')}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <div className='w-100 d-flex justify-content-between'>
                    <Button color="secondary" onClick={setImportModalOpen}>{t('close')}</Button>
                    {/* <Button color="primary" onClick={() => {}}>{t('import')}</Button> */}
                </div>
            </ModalFooter>
        </Fragment>
    )
}

export default ImportModal