import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { Container, Col, Input, Label, Button, TabContent, TabPane } from 'reactstrap'

import env from '../env/src_config';
import { headersState } from '../recoil/recoil';
import { isNull } from '../izUtils';
import { axiosError, errorStatus } from '../helpers/response';
import { transformer } from '../helpers/fields';
import Spinner from '../components/spinner/Spinner';

const SignIn = ({signIn}) => {
    const {t} =  useTranslation();
    const navigate = useNavigate();
    const { type } = useParams();
    const [headers] = useRecoilState(headersState);

    const [togglePassword, setTogglePassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState(null)

    const lsLoginInputs = 'AKODA.loginInputs';

    useEffect(() => {
        const lsInputs = JSON.parse(localStorage.getItem(lsLoginInputs))

        if (isNull(type)) {
            getData(false, false, null);
        } else if (type === 'totp-qr' || type === 'totp-code') {
            if (isNull(lsInputs)) {
                navigate('/login')
            } else {
                setInputs(lsInputs)
            }
        } else {
            navigate('/login')
        }

    }, [type]) // eslint-disable-line react-hooks/exhaustive-deps

    const getData = (save = false, check_totp = false, passedInputs) => {
        let payload = { save, check_totp }

        if (!isNull(passedInputs)) {
            if (save) setLoading(true);

            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                }
            }
        }

        axios.post(env.api + '/api/login', payload, { headers }).then(response => {
            let transformedInputs = null;
            if (!isNull(response.data.data)) {
                transformedInputs = transformer(response.data.data);
                if (!isNull(transformedInputs.totp_secret)) transformedInputs.totp_secret.disabled = true;
                setInputs(transformedInputs)
                localStorage.setItem(lsLoginInputs, JSON.stringify(transformedInputs));
            }

            if (save) {
                if (!isNull(response.data.valid) && !response.data.valid) {
                    errorStatus(response.data, t);
                } else {
                    if (!isNull(response.data.state) && response.data.state === 'error') {
                        errorStatus(response.data, t);
                    } else {
                        if (isNull(type)) {
                            if (!isNull(response.data.token)) {
                                signIn(response.data.token);
                                localStorage.removeItem(lsLoginInputs);
                            } else {
                                if (!isNull(transformedInputs) && !isNull(transformedInputs.totp_secret.value) && transformedInputs.totp_secret.value.length !== 0) {
                                    navigate('/login/totp-qr')
                                } else {
                                    navigate('/login/totp-code')
                                }
                            }
                        } else if (type === 'totp-qr') {
                            navigate('/login/totp-code')
                        } else if (type === 'totp-code') {
                            if (!isNull(response.data.token)) {
                                signIn(response.data.token);
                                localStorage.removeItem(lsLoginInputs);
                            }
                        }
                    }
                }

                setLoading(false);
            }
        }).catch(error => {
            setLoading(false);
            axiosError(error, navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        setInputs(clonedInputs);
    }

    const boolChange = (value, name) => {
        let clonedInputs = {...inputs};
        clonedInputs[name].value = !value;
        setInputs(clonedInputs);
    }

    const onKeyUp = (e, field) => {
        if (e.key === 'Enter') {
            if (field.name === 'password') {
                getData(true, false, inputs)
            } else if (field.name === 'totp_code') {
                getData(true, true, inputs)
            }
        }
    }

    const mapLinker = (field) => {
        if (inputs[field].display) {
            if (inputs[field].type === 'password') {
                return (
                    <div key={"field-"+inputs[field].name} className="mb-2 position-relative">
                        <Label className="col-form-label">{inputs[field].title}</Label>
                        <Input className="form-control" type={togglePassword ? "text" : "password"} onChange={e => textChange(e.target.value, inputs[field].name)} value={inputs[field].value} onKeyUp={(e) => onKeyUp(e, inputs[field])} disabled={inputs[field].disabled} />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span>{togglePassword ? t('hide') : t('show')}</span></div>
                    </div>
                )
            } else if (inputs[field].type === 'checkbox') {
                return (
                    <div key={"field-"+inputs[field].name} className="checkbox">
                        <Input id="remember_me" type="checkbox" checked={inputs[field].value} onChange={e => boolChange(inputs[field].value, inputs[field].name)} disabled={inputs[field].disabled} />
                        <Label className="text-muted my-0" for="remember_me">{inputs[field].title}</Label>
                    </div>
                )
            } else if (inputs[field].type === 'qr') {
                return (
                    <div key={"field-"+inputs[field].name}>
                        <center><img src={inputs[field].value} alt="qr-code" /></center>
                    </div>
                )
            } else {
                return (
                    <div key={"field-"+inputs[field].name} className="mb-2">
                        <Label className="col-form-label">{inputs[field].title}</Label>
                        <Input className="form-control" type={inputs[field].type} onChange={e => textChange(e.target.value, inputs[field].name)} value={inputs[field].value} onKeyUp={(e) => onKeyUp(e, inputs[field])} disabled={inputs[field].disabled} />
                    </div>
                )
            }
        }
    }

    if (isNull(inputs)) {
        return <Spinner />
    } else {
        return (
            <Container fluid={true} className="p-0">
                <Col xs="12">
                    <div className="login-card">
                        <div>
                            <div>
                                <Link className="logo" to="/">
                                    <img className="img-fluid for-light" src={env.api + '/media/images/logo.png'} alt="Logo" style={{ maxHeight: '50px' }} />
                                    {/* <img className="img-fluid for-dark" src={env.api + '/media/images/logo_color.png'} alt="Dark-logo" style={{ maxHeight: '50px' }}/> */}
                                </Link>
                            </div>
                            <div className="login-main login-tab">
                                <TabContent activeTab="jwt" className="content-login">
                                    <TabPane className="fade show" tabId={"jwt"}>
                                        <div className="theme-form">
                                            <h4>{t("login.title")}</h4>
                                            {isNull(type) &&
                                                <Fragment>
                                                    <p>{t('login.subTitle')}</p>
                                                    {['email', 'password'].map(field => mapLinker(field))}
                                                    <div className="login-btn mb-0">
                                                        <div className='d-flex align-items-center justify-content-between flex-wrap my-3'>
                                                            { ['remember_me',].map( field => mapLinker(field) ) }
                                                            <Link to="/password-reset/get-token">{t('login.forgotPassword')}</Link>
                                                        </div>
                                                        {loading ?
                                                            <div className="loader-box justify-content-start ms-4" style={{ height: 'auto' }}>
                                                                <div className="loader-3" style={{ height: '27px', width: '27px' }}></div>
                                                            </div>
                                                            :
                                                            <Button color="primary" onClick={() => getData(true, false, inputs)}>{t('login.btn.login')}</Button>
                                                        }
                                                    </div>
                                                </Fragment>
                                            }
                                            {type === 'totp-qr' &&
                                                <Fragment>
                                                    <p>{t('login.subTitleQr')}</p>
                                                    {['totp_qr', 'totp_secret'].map(field => mapLinker(field))}
                                                    {loading ?
                                                        <div className="loader-box justify-content-start ms-4" style={{ height: 'auto' }}>
                                                            <div className="loader-3" style={{ height: '27px', width: '27px' }}></div>
                                                        </div>
                                                        :
                                                        <Button color="primary" onClick={() => getData(true, false, inputs)}>{t('login.btn.keyAded')}</Button>
                                                    }
                                                </Fragment>
                                            }
                                            {type === 'totp-code' &&
                                                <Fragment>
                                                    <p>{t('login.subTitleCode')}</p>
                                                    {['totp_code'].map(field => mapLinker(field))}
                                                    {loading ?
                                                        <div className="loader-box justify-content-start ms-4" style={{ height: 'auto' }}>
                                                            <div className="loader-3" style={{ height: '27px', width: '27px' }}></div>
                                                        </div>
                                                        :
                                                        <Button color="primary" onClick={() => getData(true, true, inputs)}>{t('login.btn.check')}</Button>
                                                    }
                                                </Fragment>
                                            }
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default SignIn;