import axios from "axios";

import env from "../env/src_config";
import { isNull } from "../izUtils";
import { axiosError } from "./response";

export const saveTaskPrices = async (headers, id, passedInputs) => {
    let payload = { save: true, use_service_materials: false };

    if (!isNull(passedInputs)) {
        payload.data = {};
        const inputKeys = Object.keys(passedInputs);

        for (let i = 0; i < inputKeys.length; i++) {
            if (inputKeys[i] === 'items') {
                const keys = ['name', 'quantity', 'price_per_unit', 'discount', 'warranty']
                const toInt = ['quantity', 'discount'];
                const toFloat = ['price_per_unit'];

                let transformedItems = passedInputs[inputKeys[i]].value.map(value => {
                    let item = {};

                    for (let i = 0; i < keys.length; i++) {
                        if (!isNull(value[keys[i]]) && value[keys[i]].length !== 0) {
                            if (toFloat.indexOf(keys[i]) !== -1) {
                                let val = value[keys[i]].replace(/\./g, '').replace(',', '.');
                                item[keys[i]] = val
                            } else if (toInt.indexOf(keys[i]) !== -1) {
                                let val = value[keys[i]]+'';
                                val = val.replace(/\./g, '').split(',');
                                item[keys[i]] = val[0]
                            } else {
                                item[keys[i]] = value[keys[i]]
                            }
                        } else {
                            if (toFloat.indexOf(keys[i]) !== -1) {
                                item[keys[i]] = "0.00";
                            } else if (toInt.indexOf(keys[i]) !== -1) {
                                item[keys[i]] = "0";
                            } else {
                                item[keys[i]] = "";
                            }
                        }
                    }

                    return item
                });

                payload.data[inputKeys[i]] = transformedItems;
            } else {
                if (!isNull(passedInputs[inputKeys[i]].value) && passedInputs[inputKeys[i]].value.length !== 0) {
                    payload.data[inputKeys[i]] = passedInputs[inputKeys[i]].value;
                } else {
                    payload.data[inputKeys[i]] = "";
                }
            }
        }
    }

    // If there is only one element in array -> element name is empty and element prices are 0 -> remove that element
    if (payload.data.items.length === 1) {
        if (payload.data.items[0].name === '' && payload.data.items[0].quantity === '0' && payload.data.items[0].price_per_unit === '0.00'/* && payload.data.items[0].discount === '0'*/) {
            payload.data.items = [];
        }
    }

    const taskPricesResponse = await axios.patch(env.api + '/api/task/prices/' + id, payload, {headers}).then(response => {
        return response.data
    }).catch(error => {
        axiosError(error);
    });

    return taskPricesResponse;
}