import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { Container, Row, Col, Form, Input, Label, Button, TabPane } from 'reactstrap'
import Swal from 'sweetalert2';

import env from '../env/src_config';
import { headersState } from '../recoil/recoil';
import { isNull } from '../izUtils';
import { axiosError, errorStatus } from '../helpers/response';
import { transformer } from '../helpers/fields';
import Spinner from '../components/spinner/Spinner';

const PasswordResset = () => {
    const {t} =  useTranslation();
    const Navigate = useNavigate();
    const { token } = useParams()
    const [headers] = useRecoilState(headersState);

    const hasToken = useRef(token !== 'get-token')
    const [inputs, setInputs] = useState(null)

    useEffect(() => {
        getData(false);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getData = (save = false) => {
        let payload = { save, platform: 'desktop' }
        if (!isNull(inputs)) {
            const keys = Object.keys(inputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(inputs[keys[i]].value) && inputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = inputs[keys[i]].value;
                }
            }
        }


        const url = env.api + '/api/reset-password' + (hasToken.current ? '/'+token : '')
        axios.post(url, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.valid) && !response.data.valid) {
                    errorStatus(response.data, t)
                } else if (!isNull(response.data.message)) {
                    Swal.fire({
                        title:  t('passwordReset.success.title'),
                        text:  response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok')
                    }).then(value => {
                        if (value.isConfirmed && hasToken.current) Navigate('/')
                    })
                }
            }

            if (!isNull(response.data.state) && response.data.state === 'error') errorStatus(response.data, t)
            if (!isNull(response.data.data)) setInputs(transformer(response.data.data))
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        setInputs(clonedInputs);
    }

    const mapLinker = (field) => {
        if (inputs[field]) {
            return (
                <div key={"field-"+inputs[field].name} className="mb-2">
                    <Label className="col-form-label">{inputs[field].title}</Label>
                    <Input className="form-control" type={inputs[field].type} onChange={e => textChange(e.target.value, inputs[field].name)} value={inputs[field].value} />
                </div>
            );
        }
    }

    if (isNull(inputs)) {
        return <Spinner />
    } else {
        return (
            <Container fluid={true} className="p-0">
                <Row>
                    <Col xs="12">
                        <div className="login-card">
                            <div>
                                <div>
                                    <Link className="logo" to="/">
                                        <img className="img-fluid for-light" src={env.api + '/media/images/logo.png'} alt="Logo" style={{ maxHeight: '50px' }} />
                                        {/* <img className="img-fluid for-dark" src={env.api + '/media/images/logo_color.png'} alt="Dark-logo" style={{ maxHeight: '50px' }}/> */}
                                    </Link>
                                </div>
                                <div className="login-main login-tab">
                                    <TabPane className="fade show" tabId={"jwt"}>
                                        <Form className="theme-form">
                                            <h4 className='mb-4'>{t('passwordReset.title')}</h4>
                                            { ['email', 'password', 'password_confirmation'].map( field => mapLinker(field) ) }
                                            <div className="login-btn mb-0">
                                                <Button color="primary" className="mt-2" onClick={() => getData(true)}>{hasToken.current ? t('passwordReset.changePassword') : t('passwordReset.sendEmail')}</Button>
                                                {/* <Button color="primary" className="mt-2" onClick={() => getData(true)}>{t('passwordReset.btn')}</Button> */}
                                            </div>
                                            <p className="mt-4 mb-0">{t('passwordReset.back')}<Link className="ms-2" to="/login">{t('passwordReset.login')}</Link></p>
                                        </Form>
                                    </TabPane>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default PasswordResset;