import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { Button, FormGroup } from 'reactstrap'
import DataTable from 'react-data-table-component'

import { customStyles } from '../../../izUtils'

const PhonebookList = ({data, handleDelete}) => {
    const Navigate = useNavigate()

    const handleRowClick = (state) => {
        Navigate('/phonebook/' + state.id + '/get')
    }

    const handleEdit = (state) => {
        Navigate('/phonebook/' + state.id + '/update')
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        let columns = [
            {
                name: t('table.name'),
                selector: row => row.name,
                sortable: true,
            },
            {
                name: t('table.email'),
                selector: row => row.email,
                sortable: true,
            },
            {
                name: t('table.phone'),
                selector: row => row.phone,
                sortable: true,
            },
            {
                name: t('table.main_supervisor'),
                selector: row => row.main_supervisor,
                cell: row => {
                    return (
                        <div onClick={() => handleRowClick(row)}>
                            {row.main_supervisor ?
                                <div className='badge bg-success'>{t('yes')}</div>
                                :
                                <div className='badge bg-danger'>{t('no')}</div>
                            }
                        </div>
                    )
                },
                sortable: true,
            }
        ];

        let hideActions = true;
        for (let i = 0; i < data.length; i++) {
            if (data[i].permissions.delete || data[i].permissions.update) {
                hideActions = false;
                break;
            }
        }

        if (!hideActions) {
            columns.push({
                name: t('table.actions'),
                sortable: false,
                cell: row => {
                    return (
                        <div>
                            {row.permissions?.update && <Button color='primary' className="me-2 my-1" onClick={() => handleEdit(row)}>{t('edit')}</Button>}
                            {row.permissions?.delete && <Button color='primary' className="my-1" onClick={() => handleDelete(row)}>{t('delete')}</Button>}
                        </div>
                    )
                },
            });
        }

        return columns;
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default PhonebookList