import React, { useState, useEffect, useMemo } from 'react'
import { t } from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup, Label, Input } from 'reactstrap'

import { isNull, customStyles, paginationComponentOptions } from '../../../izUtils'
import { FilterComponentText/*, FilterComponentSelect*/ } from '../../../components/task/list/FilterComponent'

const EventTechnicians = ({ data, saveToLS, showErrors, updateData }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [filterText, setFilterText] = useState("");
    // const [filterSelect, setFilterSelect] = useState("");

    useEffect(() => {
        let selected = [];
        const tableTechnicians = data.values.map(row => {
            if (!isNull(data.value) && data.value.length !== 0) {
                if (data.value.indexOf(row.id) !== -1) selected.push(row.id)
            }

            return {...row}
        })

        setSelectedRows(selected)
        localStorage.setItem(saveToLS, JSON.stringify(selected))
        setTableData(tableTechnicians)

    }, [JSON.stringify(data.values)]) // eslint-disable-line react-hooks/exhaustive-deps

    // Create table columns
    const tableColumns = useMemo(() => {
        let columns = [
            { name: t('table.name'), selector: row => row.title, sortable: true },
        ]

        if (!data.disabled) {
            columns.unshift(
                {
                    name: '',
                    width: '50px',
                    cell: row => {
                        let isSelected = false;
                        if (selectedRows.indexOf(row.id) !== -1) isSelected = true;
                        return (
                            <div className="custom-control custom-checkbox form-check">
                                <div className="form-checkbox-group">
                                    <Input
                                        className={"custom-control-input"}
                                        id={row.id + '-' + saveToLS}
                                        type="checkbox"
                                        onChange={() => handleRowSelected(row)} checked={isSelected}
                                    />
                                    <Label className="checkmark secondary" htmlFor={row.id + '-' + saveToLS}></Label>
                                </div>
                            </div>
                        )
                    }
                },
            )
        }

        return columns;
    }, [selectedRows, data.disabled]) // eslint-disable-line react-hooks/exhaustive-deps

    // Row handlers
    const handleRowSelected = (row) => {
        let clonedSelectedRows = [...selectedRows];
        if (clonedSelectedRows.indexOf(row.id) === -1) {
            clonedSelectedRows.push(row.id)
        } else {
            let filtered = clonedSelectedRows.filter(selected => selected !== row.id)
            clonedSelectedRows = filtered;
        }
        setSelectedRows(clonedSelectedRows)
        localStorage.setItem(saveToLS, JSON.stringify(clonedSelectedRows))
        updateData()
    }
    // End row handlers

    const subHeaderComponent = useMemo(() => {
        const handleClear = (type) => {
            // if (type === 'select') {
            //     setFilterSelect("");
            // } else if (type === 'text') {
                setFilterText("");
            // }
        };

        return (
            <div className='w-100 d-flex flex-wrap justify-content-between'>
                {/* <FilterComponentSelect
                    onFilter={e => setFilterSelect(e.target.value)}
                    onClear={handleClear}
                    filterValue={filterSelect}
                /> */}
                <FilterComponentText
                    onFilter={e => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterValue={filterText}
                />
            </div>
        );
      }, [filterText/*, filterSelect*/]);

    let filteredItems = useMemo(() => {
        let filteredTableData = tableData.filter(item => {
            return (item.title && item.title.toLowerCase().includes(filterText.toLowerCase()))
        });

        // filteredTableData = filteredTableData.filter(item => {
        //     return (filterSelect === "" || item.department.indexOf(filterSelect) !== -1)
        // });

        return filteredTableData;
    }, [tableData, filterText/*, filterSelect*/])

    return (
        <FormGroup className={"table-with-filters"}>
            <Label>{data.title + (data.mandatory ? " *" : "")}</Label>
            {tableData.length !== 0 ?
                <DataTable
                    columns={tableColumns}
                    data={filteredItems}
                    pagination
                    paginationComponentOptions={paginationComponentOptions(t)}
                    customStyles={customStyles}
                    subHeader
                    subHeaderComponent={subHeaderComponent}
                />
                :
                <div>{t('table.noTechnicians')}</div>
            }
            {!isNull(showErrors) && showErrors && !isNull(data.errors) && data.errors.length !== 0 &&
                data.errors.map((error, index) => (
                    <div key={'material-error-'+index} style={{ color: "red", marginBottom: "10px" }}>{error}</div>
                ))
            }
        </FormGroup>
    )
}

export default EventTechnicians