import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Row, Col, CardBody, Form, FormGroup, CardHeader, Button, Card, CardFooter } from 'reactstrap'
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';

import env from '../../env/src_config';
import { transformer, linker } from '../../helpers/fields'
import { headersState, tokenState, multiSelectLoading, materialLoading, serviceDraft } from '../../recoil/recoil';
import { isNull } from '../../izUtils'
import { errorStatus, axiosError, errorStatusDraft} from '../../helpers/response';
import ServiceTechnicians from './list/ServiceTechnicians'
import Spinner from '../spinner/Spinner';

const CreateService = ({ permissions }) => {
    const {t} = useTranslation();
    const Navigate = useNavigate();
    const {id} = useParams();

    const headers = useRecoilValue(headersState);
    const token = useRecoilValue(tokenState);
    const setMultiLoading = useSetRecoilState(multiSelectLoading);
    const setMaterialLoading = useSetRecoilState(materialLoading);
    const serviceDraftData = useRecoilValue(serviceDraft);
    const [inputs, setInputs] = useState(null);
    const [showErrors, setShowErrors] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [technicianSelected, setTechnicianSelected] = useState(false);
    const [getDataCalled, setGetDataCalled] = useState(false);

    const lsTaskTechicians = 'AKODA.taskTechnicians-'+id;
    const lsServiceInputs = 'AKODA.serviceInputs';
    const lsTechicians = 'AKODA.serviceTechnicians';

    useEffect(() => {
        console.log("serviceDraftData:",serviceDraftData)
        if (serviceDraftData.isDraft) {
            if (!isNull(serviceDraftData.values)) {
                if (!isNull(serviceDraftData.values.technicians.values)) {
                    if (permissions['service.update']) {
                        localStorage.setItem(lsTechicians, JSON.stringify(serviceDraftData.values.technicians.value))
                    } else {
                        localStorage.setItem(lsTechicians, JSON.stringify(serviceDraftData.values.technicians.values))
                    }
                }

                getData(false, serviceDraftData.values, false, null);
            }
        } else {
            let payload = null;
            const lsTaskInputs = JSON.parse(localStorage.getItem('AKODA.taskInputs'));

            // Add service_type from task
            if (!isNull(lsTaskInputs) && !isNull(lsTaskInputs.service_type) && !isNull(lsTaskInputs.service_type.value) && lsTaskInputs.service_type.value.length !== 0) {
                payload = { service_type: {} };
                switch (lsTaskInputs.service_type.value) {
                    case "service":
                        payload.service_type.value = ['service_type_intervention_repair']
                        break;
                    case "upgrade":
                        payload.service_type.value = ['service_type_upgrade']
                        break;
                    case "flat_rate":
                        payload.service_type.value = ['service_type_inspection']
                        break;
                    default:
                        break;
                }
            }

            // Add security_systems from task
            if (lsTaskInputs?.security_systems?.value && lsTaskInputs.security_systems.value.length !== 0) {
                payload.security_systems = {};
                payload.security_systems.value = lsTaskInputs.security_systems.value;
            }

            // Add technicians from task
            const taskTechnicians = JSON.parse(localStorage.getItem(lsTaskTechicians));
            let technicians = taskTechnicians?.map(technician => {
                return {
                    id: technician,
                    // from: null,
                    to: moment().format('HH:mm'),
                }
            })
            localStorage.setItem(lsTechicians, JSON.stringify(technicians))

            getData(false, payload, false, null);
        }

        return () => {
            localStorage.removeItem(lsServiceInputs)
            localStorage.removeItem(lsTechicians)
        }
    }, [serviceDraftData.isDraft, serviceDraftData.values]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // The problem is if user is focused on text field and then checks/unchecks technician in table.
        // The text field onBlur and checking technician both trigger the call to API.
        // The bottom if statement fixes the problem.
        if (technicianSelected && !getDataCalled) {
            getData(false, JSON.parse(localStorage.getItem(lsServiceInputs)), false, null)
            setTechnicianSelected(false);
        }

    }, [technicianSelected, getDataCalled]) // eslint-disable-line

    const getData = (save, passedInputs, saveDraft, type)  => {
        setGetDataCalled(true);
        if (save || saveDraft) setDisableBtn(true);
        let payload = { save, save_draft: saveDraft };

        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }

            // Get data from localStorage
            let selectedTechnicians = JSON.parse(localStorage.getItem(lsTechicians))
            if (!isNull(selectedTechnicians)) {
                if (isNull(payload.data)) payload.data = {};
                payload.data.technicians = selectedTechnicians.map(technician => {
                    return {
                        id: technician.id,
                        from: technician.from,
                        to: technician.to,
                        programming_work: technician.programming_work,
                        remote_work: technician.remote_work,
                    }
                })
            }
        }

        axios.post(env.api + '/api/service/create/' + id, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title:  t('saved'),
                        text:  response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        Navigate('/')
                    });
                } else {
                    errorStatus(response.data, t);
                    setShowErrors(true);
                    setDisableBtn(false)
                }
            } else if (saveDraft) {
                if (type === 'sign') {
                    const isValid = errorStatusDraft(response.data, t, ['signature']);
                    if (!isValid) {
                        setShowErrors(true);
                        setDisableBtn(false)
                    } else {
                        Navigate('/task/' + id + '/service-sign/' + response.data.id)
                    }
                } else {
                    setDisableBtn(false)
                    if (!isNull(response.data.state) && response.data.state === 'success') {
                        Swal.fire({
                            title: t('saved'),
                            text: response.data.message,
                            icon: 'success',
                            confirmButtonColor: 'var(--theme-default)',
                            confirmButtonText: t('ok'),
                        })/*.then(() => {
                            Navigate('/')
                        });*/
                    } else {
                        errorStatusDraft(response.data, t);
                    }
                }
            }

            setMultiLoading(false) // Set loading for field MultiSelect to false
            setMaterialLoading(false) // Set loading for field Materials to false
            if (!isNull(response.data.data)) {
                let transformedResponse = transformer(response.data.data)

                // Disable signature and hide it if value is empty
                transformedResponse.signature.disabled = true;
                if (isNull(transformedResponse.signature.value) || transformedResponse.signature.value === '') transformedResponse.signature.display = false;

                setInputs(transformedResponse);
                localStorage.setItem(lsServiceInputs, JSON.stringify(transformedResponse));
            }

            setGetDataCalled(false);
        }).catch(error => {
            axiosError(error, Navigate);
            setGetDataCalled(false);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        if (name === 'signature_status' && (value === 'client_unavailable' || value === 'client_rejected_signature')) clonedInputs.signature.value = ''
        getData(false, clonedInputs, false, null);
    }

    const multiChange = (value, name, component) => {
        setMultiLoading(name + '-' + component?.id)
        let clonedInputs = {...inputs};
        if (clonedInputs[name].value.length === 0) {
            clonedInputs[name].value.push(value)
        } else {
            if (clonedInputs[name].value.indexOf(value) === -1) {
                clonedInputs[name].value.push(value);
            } else {
                const filteredValues = clonedInputs[name].value.filter(el => el !== value);
                clonedInputs[name].value = filteredValues;
            }
        }
        getData(false, clonedInputs, false, null);
    }

    const handleSelectTechnician = () => {
        setTechnicianSelected(true);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            component: {id: 'createService'},
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            multiHandler: (value, id, component) => multiChange(value, id, component),
            dateHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    if (isNull(inputs)) {
        return <Spinner />
    } else {
        return (
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader>
                            <h5>{t('service.title')}</h5>
                        </CardHeader>
                        <CardBody>
                            <Form className="theme-form">
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            { ['technician_count', 'vehicle_count', 'work_description', 'security_systems'].map(field => mapLinker(field)) }
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            { ['service_type', 'service_type_other', 'materials', 'submitted_at', 'work_incomplete' ].map(field => mapLinker(field)) }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <ServiceTechnicians data={inputs.technicians} saveToLS={lsTechicians} showErrors={showErrors} getData={handleSelectTechnician} type='create' />
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            { ['signature_status', 'signature_notes', 'signatory_name', 'signature'].map(field => mapLinker(field)) }
                                        </FormGroup>
                                        {(inputs.signature_status.value === 'client_available') &&
                                            <Button color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => getData(false, inputs, true, 'sign')}>{t('service.saveSign')}</Button> /* Sign button */
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                        <CardFooter>
                            <div className='text-end'>
                                {/* Save Draft button */}
                                <Button color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => getData(false, inputs, true, 'saveDraft')}>{t('service.saveDraft')}</Button>
                                {(/*inputs.signature_status.value !== 'client_available'*/token.parsedToken.type === 'admin') &&
                                    <Button color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => getData(true, inputs, false)}>{t('service.saveCurrent')}</Button>
                                }
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default CreateService