import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup } from 'reactstrap'

import { customStyles } from '../../../izUtils'

const PriceList = ({data, tokenData}) => {
    const Navigate = useNavigate();

    const handleRowClick = (state) => {
        Navigate('/projects/' + state.id + '/get');
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        let columns = [
            {
                name: t('table.project_name'),
                selector: row => row.project_name,
                sortable: true,
            },
            {
                name: t('table.client'),
                selector: row => row.client?.client,
                sortable: true,
            },
            {
                name: t('table.facility'),
                selector: row => row.facility?.name,
                sortable: true,
            },
            {
                name: t('table.project_lead'),
                selector: row => row.project_lead,
                sortable: true,
            },
        ]

        if (tokenData.parsedToken.type === 'admin') {
            columns.push( {
                name: t('edit'),
                sortable: false,
                cell: row => {
                    return (
                        <button className='btn btn-secondary' onClick={(e) => Navigate('/projects/' + row.id + '/update')}><i className="fa fa-pencil"></i></button>
                    )
                }
            })
        } else if (tokenData.parsedToken.type === 'technician') {
            columns.push( {
                name: t('table.addLog'),
                sortable: false,
                cell: row => {
                    return (
                        <button className='btn btn-secondary' onClick={() => Navigate('/projects/' + row.id + '/logs/create')}><i className="fa fa-plus-square"></i></button>
                    )
                }
            })
        }

        return columns;
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default PriceList