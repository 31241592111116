import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { FormGroup } from 'reactstrap'
import DataTable from 'react-data-table-component'

import { customStyles, displayDate, isNull } from '../../../izUtils'

const StandbyList = ({data, handleDelete}) => {
    const Navigate = useNavigate()

    const handleRowClick = (state) => {
        Navigate('/schedule/standby/' + state.id + '/update')
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        return [
            {
                name: t('table.from'),
                selector: row => !isNull(row.from) ? displayDate(row.from) : '',
                sortable: true,
            },
            {
                name: t('table.to'),
                selector: row => !isNull(row.to) ? displayDate(row.to) : '',
                sortable: true,
            },
            {
                name: t('table.standby_technician_1'),
                selector: row => !isNull(row.standby_technician_1) ? row.standby_technician_1.name : '',
                sortable: true,
            },
            {
                name: t('table.standby_technician_2'),
                selector: row => !isNull(row.standby_technician_2) ? row.standby_technician_2.name : '',
                sortable: true,
            },
            {
                name: t('table.standby_technician_cns'),
                selector: row => !isNull(row.standby_technician_cns) ? row.standby_technician_cns.name : '',
                sortable: true,
            },
            {
                name: t(''),
                sortable: false,
                cell: row => <div className='m-auto' onClick={() => handleDelete(row)}><i className="icon-trash bigger-icon p-2"></i></div>
            },
        ]
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default StandbyList