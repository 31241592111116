import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import {t} from 'i18next'
import axios from 'axios';
import { Card, Form, CardBody, CardFooter, Button } from 'reactstrap';
import Swal from 'sweetalert2'

import env from '../../../../env/src_config';
import { axiosError, errorStatus } from '../../../../helpers/response';
import { headersState } from '../../../../recoil/recoil';
import { isNull } from '../../../../izUtils';
import { transformer, linker } from '../../../../helpers/fields';
import Spinner from '../../../spinner/Spinner';

/* Modal for adding new component to security system */
const AddSecuritySystemComponent = ({modalType, getSecuritySystemComponents, setModalAddSecuritySystemComponent}) => {
    const navigate = useNavigate();
    const { linkedSystemId } = useParams();
    const headers = useRecoilValue(headersState);

    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        updateData(false, null);

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        let endpoint = '/api/client/facility/system/components/update/' + modalType;
        let method = 'patch';
        if (modalType === 'create') {
            endpoint = '/api/client/facility/system/components/create/' + linkedSystemId;
            method = 'post';
        }

        axios[method](env.api + endpoint, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    })
                    setModalAddSecuritySystemComponent(false);
                    getSecuritySystemComponents({});
                } else {
                    setShowErrors(true);
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        if (name === 'manufacturer') {
            clonedInputs['system'].value = "";
            clonedInputs['central_station'].value = "";
        } else if (name === 'system') {
            clonedInputs['central_station'].value = "";
        }
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    if (isNull(inputs)) {
        return <Spinner />;
    } else {
        return (
            <Card className="ribbon-wrapper" style={{ marginBottom: '0px' }}>
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-primary">{modalType === 'create' ? t('facilities.linkedSystems.securitySystemComponent.create.title') : t('facilities.linkedSystems.securitySystemComponent.edit.title')}</div>
                        <Fragment>
                            <Form className="theme-form">
                                { ['manufacturer', 'system', 'central_station', 'component_notes', 'component_number'].map(field => mapLinker(field)) }
                            </Form>
                        </Fragment>
                </CardBody>
                <CardFooter>
                    <div className='d-flex justify-content-between'>
                        <Button color="secondary" className="mt-2 me-2" onClick={() => setModalAddSecuritySystemComponent(false)}>{t('cancel')}</Button>
                        <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{modalType === 'create' ? t('add') : t('edit')}</Button>
                    </div>
                </CardFooter>
            </Card>
        )
    }
}

export default AddSecuritySystemComponent