import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Button, Form, Card, CardBody, CardFooter, Row, Col } from 'reactstrap'
import Swal from 'sweetalert2'

import env from '../../env/src_config'
import { headersState, tokenState, updateProjectSystems } from '../../recoil/recoil'
import { axiosError } from '../../helpers/response'
import { isNull } from '../../izUtils'
import { transformer, linker } from '../../helpers/fields'
import Spinner from '../spinner/Spinner'
import { errorStatus } from '../../helpers/response'
import { getSingleItem } from '../../helpers/getSingleItem'
import DisplayFields from '../../helpers/displayFields'
import ProjectSystems from './system/ProjectSystems'
import { saveProjectSystem } from '../../helpers/saveProjectSystem'

const AddUpdateProject = () => {
    const Navigate = useNavigate();
    const { projectId, projectMethod } = useParams();

    const headers = useRecoilValue(headersState);
    const token = useRecoilValue(tokenState);
    const [updatePS, setUpdatePS] = useRecoilState(updateProjectSystems);

    const [inputs, setInputs] = useState(null)
    const [projectSystemsIds, setProjectSystemsIds] = useState([])
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        if (!isNull(inputs)) setInputs(null)
        setShowErrors(false);

        if (projectId === 'create' || projectMethod === 'update') {
            updateData(false, null);
        } else {
            getSingleItem(headers, projectId, '/api/project/', Navigate).then(data => {
                if (!isNull(data)) {
                    setInputs(data.fields);
                    setProjectSystemsIds(data.otherData.project_security_systems);
                }
            })
        }

        return () => {
            setUpdatePS([])
        }
    }, [projectId, projectMethod]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        let method = "patch";
        if (projectId === 'create') method = "post";
        axios[method](env.api + '/api/project/' + projectId, payload, {headers}).then(async (response) => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {

                    // Loop through projectSystems and save them
                    let isValid = true;
                    for (let index = 0; index < updatePS.length; index++) {
                        let lsData = JSON.parse(localStorage.getItem('AKODA.projectSystemInputs-'+updatePS[index]));
                        isValid = await saveProjectSystem(headers, updatePS[index], lsData, true).then(res => {
                            if (!res.valid) {
                                setShowErrors(true)
                                errorStatus(res, t);
                            }
                            return res.valid;
                        })

                        if (!isValid) break;
                    }

                    if (!isValid) return;
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        if (projectId === 'create') {
                            Navigate('/projects/' + response.data.id + '/update')
                        } else {
                            Navigate('/projects/')
                        }
                    })
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) {
                setInputs(transformer(response.data.data));
            }

            if (!isNull(response.data.project_security_systems)) {
                setProjectSystemsIds(response.data.project_security_systems);
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
        }

        if (projectMethod === 'get') {
            if (!isNull(inputs[field])) return <DisplayFields key={'display-'+inputs[field].name} data={inputs[field]} />
        } else {
            return linker(inputlinkerFields);
        }
    }

    return (
        <>
            <Card className="ribbon-wrapper">
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-primary">{ (projectId === 'create') ? t('projects.create.title') : ( projectMethod === 'update' ? t('projects.update.title') : t('projects.get.title') ) }</div>
                    {isNull(inputs) ?
                        <Spinner />
                        :
                        <Form className="theme-form">
                            <Row>
                                <Col sm="12">
                                    { ['project_name'].map(field => mapLinker(field)) }
                                    <Row>
                                        <Col sm="10">
                                            { ['client'].map(field => mapLinker(field)) }
                                        </Col>
                                        {(projectId === 'ccreate' || projectMethod === 'update') &&
                                            <Col sm="2">
                                                <div className="text-end">
                                                    <Button color="primary" className="mt-4" onClick={() => Navigate('/clients/create')}>{t('addClient')}</Button>
                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                    <Row>
                                        <Col sm="10">
                                            { ['facility'].map(field => mapLinker(field)) }
                                        </Col>
                                        {(!isNull(inputs.client.value) && inputs.client.value !== '' && (projectId === 'ccreate' || projectMethod === 'update')) &&
                                            <Col sm="2">
                                                <div className="text-end">
                                                    <Button color="primary" className="mt-4" onClick={() => Navigate('/clients/' + inputs.client.value + '/facilities/create')}>{t('addFacility')}</Button>
                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                    { ['project_number', 'project_lead'].map(field => mapLinker(field)) }
                                    {(projectId !== 'create' && token.parsedToken.type !== 'technician' ) && <ProjectSystems projectSystemsIds={projectSystemsIds} showErrors={showErrors} />}
                                </Col>
                            </Row>
                        </Form>
                    }
                </CardBody>
                {(projectId === 'create' || (projectMethod === 'update')) &&
                    <CardFooter>
                        <div className='text-end'>
                            <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{t('save')}</Button>
                        </div>
                    </CardFooter>
                }
            </Card>
            {(projectId !== 'create' && !isNull(inputs) && !isNull(inputs.project_logs) && !isNull(inputs.project_logs.value) && inputs.project_logs.value.length !== 0) &&
                ['project_logs'].map(field => mapLinker(field))
            }
        </>
    )
}

export default AddUpdateProject